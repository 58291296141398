import React from 'react';

const CharacterCount = (props) => {
    
    return (
        <>
            <p className={`fs-12p mb-1 mt-1 text-end ${(props.string && props.string.length > props.max) ? 'text-danger' : ''}`}>
                <b>{props.string ? props.string.length : 0}</b>/{props.max} characters
            </p>
        </>
    );
  }

export {
    CharacterCount
};