/* eslint-disable-next-line */
import React, {useEffect, lazy, Suspense} from "react";
import { useDispatch} from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import {update_device_data, auth_token_update} from './actions/action-creators/state-update';
//Css
import './assets/vendors/mdi/css/materialdesignicons.min.css';
import './assets/vendors/css/vendor.bundle.base.css';
import './assets/css/style.css';
import NotFound from './screens/404';
import {LoaderMedium} from './components/loader';
// const Home = lazy(() => import('./screens/home'));
import Dashboard from './screens/dashboard/main';
import StudentList from './screens/student/list';
import FellowList from './screens/fellow/list';
import HubList from './screens/hub/list';
import StakeholderList from './screens/stakeholder/list';
// import Home from './screens/home';
import WrapMaster from './components/wrapper/main';
// Auth
import Login from './screens/auth/login';
import Logout from "./screens/auth/logout";
// const loading = (
//     <div className="preloader flex-column justify-content-center align-items-center">
//       <img className="animation__shake" src={logo} alt="IFSPD" height="200" width="200" />
//     </div>
// )
//Public
// const Home = lazy(() => import('./screens/home'));
// const WrapMaster = lazy(() => import('./components/wrapper/main'));
import PrivateRoute from "./routes/private";
import AuthRoute from "./routes/auth";

function App() {
    const dispatch = useDispatch();
    // const getDeviceId = () => {
    //     return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    //       var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    //       return v.toString(16);
    //     });
    // }
    useEffect(() => {
        //Check if Device Id Set, if not Set New
        if(!localStorage.getItem("device_id")){
            const deviceID = uuidv4();
            //Dispatch and check if it is available in database
            update_device_data({device_id: deviceID});
            // console.log(deviceID, 'deviceID')
        }
        // else{
        //     console.log('nnnnnnnnnnnn')
        // }
        dispatch(auth_token_update());
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
      
    return (
        <>
        {/* <Suspense fallback={loading}> */}
        <div id="bodyOverlay">
            <LoaderMedium size={65} />
        </div>
        <ReactNotification />
        {/* <Header/> */}
        {/* <Navbar /> */}
        {/* <div className="wrapper" style={{minHeight: '70vh'}}> */}
            {/* <div className="container"> */}
            
            <Router>
                <Routes>
                    <Route path='*' element={<WrapMaster title="Page Not Found"><NotFound/></WrapMaster>}/>
                    {/* Auth: Guest Route */}
                    <Route element={<AuthRoute/>}>
                        <Route exact path='/login' element={<Login/>}/>
                        {/* <Route exact path='/reset-password' element={<ResetPassword/>}/> */}
                    </Route>
                    {/* Routh For Authenticated User */}
                    <Route element={<PrivateRoute/>}>
                        <Route exact path="/" element={<WrapMaster title="Dashboard"><Dashboard/></WrapMaster>}/>
                        <Route exact path="/manage-students" element={<WrapMaster title="Manage Students"><StudentList/></WrapMaster>}/>
                        <Route exact path="/manage-fellows" element={<WrapMaster title="Manage Fellows"><FellowList/></WrapMaster>}/>
                        {/* <Route exact path='/verification' element={<Verification/>}/> */}
                        {/* <Route exact path='/checkout/address' element={<MyAddress/>}/> */}
                        {/* Hub */}
                        <Route exact path="/manage-opportunities" element={<WrapMaster title="Manage Opportunities"><HubList/></WrapMaster>}/>
                        {/* Admin Stakeholder */}
                        <Route exact path="/manage-admins" element={<WrapMaster title="Manage Admins"><StakeholderList/></WrapMaster>}/>
                    </Route>
                    {/* Public Route */}
                    {/* <Route path='/' element={<PublicRoute/>} /> */}
                    {/* <Route path='/'>
                        <PublicRoute />
                    </Route> */}
                    {/* Public Route */}
                    <Route exact path='/logout' element={<WrapMaster><Logout/></WrapMaster>}/>
                    {/* <Route exact path="/home" element={<WrapMaster><Home/></WrapMaster>}/> */}
                </Routes>
                {/* <PublicRoute /> */}
            </Router>
            
            {/* </div> */}
        {/* </div> */}
        {/* <Footer /> */}
        {/* </Suspense> */}
        </>
    );
}
  
export default App;