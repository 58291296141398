import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
// import { useSelector } from 'react-redux';
const isAuth = localStorage.getItem("token") ? true : false;
// const isVerified = localStorage.getItem("is_verified") && parseInt(localStorage.getItem("is_verified")) === 1 ? true : false;
const isVerified = localStorage.getItem("is_verified") ? parseInt(localStorage.getItem("is_verified")) : 0;
console.log(isVerified, localStorage.getItem("is_verified"), 'is_verified')
// console.log(isVerified, localStorage.getItem("is_verified"), 'is_verified')
// const authData = localStorage.getItem("token") ? true : false;

const RoutePrivate = () => {
  const location = useLocation();
  // const urlQuryString = location.search;
  // const urlParam = new URLSearchParams(urlQuryString);
  // console.log(urlQuryString, 'urlQuryString')
  //Store Data
  // const authReduxData = useSelector(state => state.auth);
  // console.log('authReduxData', authReduxData)
  // const [isVerified, setisVerified] = useState(0);
  // //Check if user is verified or not
  // useEffect(() => {
  //   if(isAuth
  //     && authReduxData.user_info !== null
  //     && location.pathname !== '/verification'
  //   ){
  //     const authData = JSON.parse(authReduxData.user_info);
  //     if(authData.is_verified === 1){
  //       setisVerified(1);
  //     }
  //   }
  // }, [authReduxData]);// eslint-disable-line react-hooks/exhaustive-deps
  
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return isAuth ?
          // ? !isVerified && location.pathname !== '/verification'
          // ? (isVerified === 2 || isVerified === 0) && location.pathname !== '/verification'
          // location.pathname === '/register' ||location.pathname === '/login'
          // ?
          // <Navigate
          //   to={{
          //       pathname: `${location.pathname}`,
          //       state: {from: location.pathname}
          //     }}
          // />
          // :

            // ?  <Navigate
            //     to={{
            //         pathname: `/verification?redirect_uri=${location.pathname}`,
            //         state: {from: location.pathname}
            //       }} />
            // : 
            <Outlet />
          :  <Navigate
              to={{
                  pathname: `/login?redirect_uri=${location.pathname}`,
                  state: {from: location.pathname}
                }} />;
}

export default RoutePrivate;



// import { Navigate, Route } from 'react-router-dom';
// const isAuth = localStorage.getItem("token") ? true : false;
// // console.log('isAuth', isAuth)
// const RoutePrivate = ({component: Component, ...rest}) => {
//     <Route {...rest} render={props => (
//     //   window.globalState.isAuthenticated() ? (
//     isAuth ? (
//         <div className="container">
//           <Component {...props} />
//         </div>
//       ) : (
//         <Navigate to={{
//           pathname: '/login',
//           state: {from: props.location}
//         }} />
//       )
//     )} />
// }

// export default RoutePrivate;
// import React from 'react';
// import { Navigate, Outlet, useLocation } from 'react-router-dom';
// // import { useSelector } from 'react-redux';
// const isAuth = localStorage.getItem("token") ? true : false;
// // const isVerified = localStorage.getItem("is_verified") && parseInt(localStorage.getItem("is_verified")) === 1 ? true : false;
// const isVerified = localStorage.getItem("is_verified") ? parseInt(localStorage.getItem("is_verified")) : 0;
// console.log(isVerified, localStorage.getItem("is_verified"), 'is_verified')
// // console.log(isVerified, localStorage.getItem("is_verified"), 'is_verified')
// // const authData = localStorage.getItem("token") ? true : false;

// const RoutePrivate = () => {
//   const location = useLocation();
//   // const urlQuryString = location.search;
//   // const urlParam = new URLSearchParams(urlQuryString);
//   // console.log(urlQuryString, 'urlQuryString')
//   // console.log(urlParam, 'urlParam')
//   //Store Data
//   // const authReduxData = useSelector(state => state.auth);
//   // console.log('authReduxData', authReduxData)
//   // const [isVerified, setisVerified] = useState(0);
//   // //Check if user is verified or not
//   // useEffect(() => {
//   //   if(isAuth
//   //     && authReduxData.user_info !== null
//   //     && location.pathname !== '/verification'
//   //   ){
//   //     const authData = JSON.parse(authReduxData.user_info);
//   //     if(authData.is_verified === 1){
//   //       setisVerified(1);
//   //     }
//   //   }
//   // }, [authReduxData]);// eslint-disable-line react-hooks/exhaustive-deps
  
//   // If authorized, return an outlet that will render child elements
//   // If not, return element that will navigate to login page
//   return isAuth
//           // ? !isVerified && location.pathname !== '/verification'
//           ? (isVerified === 2 || isVerified === 0) && location.pathname !== '/verification'
//             ?  <Navigate
//                 to={{
//                     pathname: `/verification?redirect_uri=${location.pathname}`,
//                     state: {from: location.pathname}
//                   }} />
//             : <Outlet />
//           :  <Navigate
//               to={{
//                   pathname: '/login',
//                   state: {from: location.pathname}
//                 }} />;
// }

// export default RoutePrivate;



// import { Navigate, Route } from 'react-router-dom';
// const isAuth = localStorage.getItem("token") ? true : false;
// // console.log('isAuth', isAuth)
// const RoutePrivate = ({component: Component, ...rest}) => {
//     <Route {...rest} render={props => (
//     //   window.globalState.isAuthenticated() ? (
//     isAuth ? (
//         <div className="container">
//           <Component {...props} />
//         </div>
//       ) : (
//         <Navigate to={{
//           pathname: '/login',
//           state: {from: props.location}
//         }} />
//       )
//     )} />
// }

// export default RoutePrivate;