import moment from 'moment';
const todayDateForm = (eligible_year='') => {
    try{
        const currentDate = new Date();
        var todayDate = '';
        const cYear = currentDate.getFullYear();
        const cDate = currentDate.getDate();
        const cMonth = currentDate.getMonth()+1;
        if(eligible_year){
            const day = cDate > 27 ? 1 : cDate;
            todayDate = `${cYear-eligible_year}-${cMonth < 10 ? 0 : ""}${cMonth}-${day < 10 ? 0 : ""}${day}`;
        }else{
            todayDate = `${cYear}-${cMonth < 10 ? 0 : ""}${cMonth}-${cDate < 10 ? 0 : ""}${cDate}`;
        }
        // console.log(todayDate, 'todayDate')
        return todayDate;
    }catch(e){
        // console.log(e)
        return null;
    }
}
const currentMonthYear = () => {
    try{
        const currentDate = new Date();
        const cMonth = currentDate.getMonth()+1;
        const todayDate = `${currentDate.getFullYear()}-${cMonth < 10 ? 0 : ""}${cMonth}`;
        return todayDate;
    }catch(e){
        return null;
    }
}


const calculateLeftDays = (date) => {
    try{
        const startDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        // const startDate = moment(loggedInDateTime, "YYYY-MM-DD HH:mm:ss");
        const endDate = moment(date, "YYYY-MM-DD HH:mm:ss");
        const differenceDays = endDate.diff(startDate, 'days');
        return differenceDays > 0 ? differenceDays : 0;
    }catch(e){
        return null;
    }
}
// const () => {
//     try{
//         const currentDate = new Date();
//         const cMonth = currentDate.getMonth()+1;
//         const cDate = `${currentDate.getDate() > 9 ? "" : 0}${currentDate.getDate()}`;
//         const currentDDMMYYYY = `${currentDate.getFullYear()}-${cMonth < 10 ? 0 : ""}${cMonth}-${cDate}`;
//         return currentDDMMYYYY;
//     }
// }

export {
    todayDateForm,
    currentMonthYear,
    calculateLeftDays
};