import React, {useState, useEffect} from "react";
import {SetMetaData} from './../../helper/page-title';
import moment from 'moment';
import ValidationHelper from "../../helper/validation";
import {post_api_call,
        error_api_call,
        get_api_call_main,
        get_api_call
      } from './../../actions/action-creators/common';
import { alertResponseMessage } from "../../actions/action-creators/response";
//Modal
import ModalMaster from "../../modal/master";
import ModalMasterDetail from "../../modal/master-detail";
import FormDataHub from "../../data/formData/stakeholder";
const {
    fieldNewStake,
    fieldValuesStake,
    fieldNewTeacher,
    // fieldValuesTeacher,
    fieldValidationStake,
    fieldValidationTeacher,
    fieldStakeView,
    fieldStakeViewTeach,
    fieldEditStake,
    fieldEditTeacher,
    fieldValidEditTeacher,
    fieldValidEditStake
} = FormDataHub;
const masterTitle = {
    new: 'New Admin Entry',
    edit: 'Edit Admin Entry',
    detail: 'Admin Details'
};
function StakeholderList(props){
    SetMetaData("Manage Admins", "", "");
    const [isLoading, setIsLoading] = useState(true);
    const [modalLoader, setModalLoader] = useState({edit: false, detail: false});
    const [modalToggle, setModalToggle] = useState({new: false,
                                                    edit: false,
                                                    detail: false});
    const [masterTableData, setMasterTableData] = useState([]);
    const [masterFieldData, setMasterFieldData] = useState([]);
    // const [newEntryFields, setNewEntryFields] = useState();
    const [activeEditForm, setActiveForm] = useState("");
    // const [formDataMaster, setFormMasterData] = useState([]);
    const [errorData, setErrorData] = useState({new: [], edit: []});
    const [formNewData, setNewFormData] = useState({});
    const [formEditData, setEditFormData] = useState({});
    const [formViewData, setViewFormData] = useState([]);
    const [formUpdatedData, setUpdatedFormData] = useState({});
    const [isProcess, setIsProcess] = useState({new: false, edit: false});
    //Single Details
    const [detailedOppData, setDetailedOppData] = useState({});
    // const [isBtnDisabled, setBtnDisabled] = useState({new: true, edit: true});
    //Options Data
    const [oppSchoolOpt, setSchoolOpt] = useState([]);
    const [oppClassOpt, setClassOpt] = useState([]);
    const [oppRoleOpt, setRoleOpt] = useState([]);
    //Get Data for Dropdown
    const fetchStakeList = async() => {
      try{
        const listApiData = await post_api_call('stakeholders', {});
        if(listApiData.status === 200){
          setMasterTableData(listApiData.data);
          setIsLoading(false);
        }
      }catch(e){
        error_api_call({error: `admin|stakeholder|list|fetchStakeList|${e}`});
      }
    }
    //Get Data from APIs
    const fetchSelectList = async(type, url, api_type="") => {
      try{
          const selectApiData = api_type !== 'api' ? await get_api_call_main(url, {}) : await get_api_call(url, {});
          var data = [];
          if(selectApiData.status === 200){
              data = selectApiData.data;
              switch (type) {
                  case 'schools':
                    setSchoolOpt([...data]);
                    break;
                  case 'classes':
                    setClassOpt(data);
                    break;
                  case 'role':
                    setRoleOpt(data);
                    break;
                  default: 
              }
          }
          return {
              data
          };
      }catch(e){
        error_api_call({error: `admin|stakeholder|list|fetchSelectList|${e}`});
        return {data: []};
      }
    }
    useEffect(() => {
        //Initial API Data
        fetchStakeList();
        fetchSelectList('schools', 'data/schools', 'api');
        fetchSelectList('classes', 'classes');
        fetchSelectList('role', 'data/role', 'api');
    }, []);
    //View Data
    const loadViewModal = async(e, load_type, user_id, role_id) => {
      e.preventDefault();
      try{
        //Page Loading
        toggleModal(e, load_type, true, '', load_type, role_id);
        setModalLoader({...modalLoader, [load_type]: true});
        const viewApiData = await post_api_call(`stakeholder/details/${user_id}`, {role_id});
        if(viewApiData.status === 200){
            if(load_type === 'detail'){
            //   if(viewApiData.data.fee_id !== 6
            //     && viewApiData.data.amount
            //   ){
            //     viewApiData.data.amount = `${viewApiData.data.currency_type === 1 ? '₹' : '$'} ${viewApiData.data.amount}`;
            //   }
              setViewFormData(role_id === 3 ? fieldStakeViewTeach : fieldStakeView);
              setDetailedOppData(viewApiData.data);
            }else{
              setUpdatedFormData({});
              //Opp Type
              var apiDataEdit = {...fieldValuesStake, ...viewApiData.data};
              if(role_id === 3){
                if(viewApiData.data.admin_class_details.length > 0){
                    var classIds = [];
                    viewApiData.data.admin_class_details.forEach(d_class => {
                        classIds.push({id: d_class.class_id, class_name: d_class.class_name});
                    });
                    apiDataEdit.class_id = classIds;
                }
              }
              
              setEditFormData({...apiDataEdit});
            }
        //   setOpenModal({true});
          setModalLoader({...modalLoader, [load_type]: false});
        }else{
          alertResponseMessage({alert_type: 2, message: "Error Loading Data"});
        }
      }catch(error){
        error_api_call({error: `admin|stakeholder|list|loadViewModal|${error}`});
        alertResponseMessage({alert_type: 2, message: "Error Loading Data"});
      }
    }
    //Approve Toggle
    const approveToggle = async(e, type, is_active, user_id, role_id) => {
        e.preventDefault();
        try{
          // status_change
          if (window.confirm(`${is_active === 1 ? 'Inactive' : 'Activate'} Admin?`)){
            const targetTag = e.target.tagName.toLowerCase();
            if(targetTag === 'i'){
                e.target.parentElement.disabled = true;
            }else{
                e.target.disabled = true;
            }
            const statusApiData = await post_api_call(`stakeholder/status-change`, {id: user_id, role_id, is_active: is_active === 2 ? 1 : 2});
            if(statusApiData.status === 200){
                fetchStakeList();
                alertResponseMessage({alert_type: 1, message: `Admin ${is_active === 1 ? 'Deactivated' : 'Activated'} Successfully!`});
            }
            if(targetTag === 'i'){
                e.target.parentElement.disabled = false;
            }else{
                e.target.disabled = false;
            }
          }
        }catch(e){
            const targetTag = e.target.tagName.toLowerCase();
            if(targetTag === 'i'){
                e.target.parentElement.disabled = false;
            }else{
                e.target.disabled = false;
            }
          error_api_call({error: `admin|stakeholder|list|approveToggle|${e}`});
        }
    }
     //Update Input
    const updateInput = (form_type, state_var_type, input_type, field, value, field_type="", form_field="", index=0) => {
      try{
        // console.log(form_type, input_type, field, value)
          if(errorData[form_type].length){
            setErrorData({...errorData, [form_type]: []});
          }

          if(field === 'mobile_number'){
              if(value.length < 16){
                  value = value.replace(/[^0-9]/g, '');
              }else{
                  return false;
              }
          }else if(input_type === 'input'
              || input_type === 'textarea'
          ){
              // value = value.replace(/ /g, '');
              value = value.trimStart();
              // value = value.replace(/  /g, '');
              if(field === 'first_name'
                  || field === 'last_name'
              ){
                  value = value.replace(/[^a-zA-Z. ]/g, '')
                    .replace('  ', ' ');
              }
          }
          var updatedFormDetails = state_var_type === 'array' 
                            ? [] 
                            : state_var_type === 'object'
                                ? {}
                                : '';
          if(field_type === 'array'){
              const fieldData = form_type === 'new' ? {...formNewData} : {...formEditData};
              fieldData[form_field][index][field] = value;
              updatedFormDetails = {...fieldData};
              // setEditFormData({...fieldData});
          }else{
            updatedFormDetails = form_type === 'new' ? {...formNewData} : {...formEditData};
            updatedFormDetails[field] = value;
              // setEditFormData({
              //     ...formEditData,
              //     [field]: value
              // });
          }
          // if(field === 'show_image'){
          //   updatedFormDetails.show_image = true;
          // }
          if(form_type === 'new'){
            setNewFormData(updatedFormDetails);
            if(field === 'role_id'){
                if(value.id === 3){//Teacher
                    setMasterFieldData([{...masterFieldData[0], fields: [...fieldNewTeacher]}]);
                }else{
                    setMasterFieldData([{...masterFieldData[0], fields: [...fieldNewStake]}]);
                }
            }
          }else{
            setEditFormData(updatedFormDetails);
          }
          if(form_type === 'edit'){
            setUpdatedFormData({...formUpdatedData, [field]: value});
            // console.log(formUpdatedData, 'formUpdatedData')
          }
      }catch(e){
        console.log(e)
      }
    }
    // Toggle Modal
    const toggleModal = (e, modal_type, toggleState, index='', form_type='', role_id='') => {
        try{
            if(toggleState){
                e.preventDefault();
            }
            if(toggleState){
                // var data = {};
                // var heading = '';
                var legendTitle = 'Details';
                switch (form_type) {
                    case 'new':
                        // heading = 'New Hub Details',
                        oppSchoolOpt.length > 1 ? setNewFormData({...fieldValuesStake}) : setNewFormData({...fieldValuesStake, school_id: oppSchoolOpt[0]});
                        setMasterFieldData([{form_type: 'new', label: legendTitle, fields: [...fieldNewStake]}]);
                        break;
                    case 'edit':
                        // heading = 'Edit Hub Details',
                        // data = {...formDataMaster[index]};
                        // setEditFormData({...data});
                        setMasterFieldData([{form_type: 'edit', label: legendTitle, fields: role_id !== 3 ? [...fieldEditStake] : [...fieldEditTeacher]}]);
                        break;
                    // case 'detail':
                    //     // heading = 'Hub Details',
                    //     data = {...formDataMaster[index]};
                    //     break;
                    default: 
                        break;
                }
                setErrorData({...errorData, [form_type]: []});
                setActiveForm(form_type);
            }
            setModalToggle({...modalToggle, [modal_type]: toggleState});
            // if((form_type === 'new'
            //     || form_type === 'edit'
            // ) && oppSchoolOpt.length === 0){
            // //   fetchSelectList('schools', 'schools');
            //   fetchSelectList('schools', 'data/schools', 'api');
            //   fetchSelectList('classes', 'classes');
            //   fetchSelectList('role', 'data/role', 'api');
            // }
        }catch(error){
            console.log(error)
        }
    }
    //Submit Details
    const updateStake = async(e, form_type='') => {
      try{
          e.preventDefault();
          setErrorData({...errorData, [form_type]: []});
          setIsProcess({...isProcess, [form_type]: true});
          var errorDetails = {
            new: [],
            edit: []
          };
          var apiFormData = new FormData();
          //Validation
          var validation = [];
          var inputData = {};
          var validationRules = {};
          switch (form_type) {
              case 'new':
                inputData = {...formNewData};
                if(formNewData.role_id && formNewData.role_id.id === 3){//Teacher
                    validationRules = fieldValidationTeacher;
                }else{
                    validationRules = fieldValidationStake;
                }
                break;
              case 'edit':
                inputData = {...formEditData};
                console.log(inputData, 'inputDatainputDatainputDatainputDatainputDatainputDatainputData')
                if(inputData.role_id === 3){//Teacher
                    console.log(fieldValidEditTeacher, 'fieldValidEditTeacher')
                    validationRules = fieldValidEditTeacher;
                }else{
                    validationRules = fieldValidEditStake;
                }
                break;
              default:
          }
          console.log(inputData, 'inputDatainputDatainputData')
          validation = ValidationHelper.validation(inputData, validationRules).error;
          if(validation.length){
            errorDetails[form_type] = validation;
          }
          
          if(errorDetails[form_type].length === 0){
            var fields = ['first_name',
                        'last_name',
                        // 'role_id',
                        'email',
                        // 'school_id', 
                        'password',
                        // 'class_id'
                    ];
            if(form_type === 'edit'){
              inputData = {...formUpdatedData};
              fields = ['first_name',
                        'last_name',
                        'password'];
            }
            fields.forEach(field => {
              if(form_type === 'new'
                || (form_type === 'edit'
                    && typeof inputData[field] !== "undefined")
              ){
                apiFormData.append(field, inputData[field]);
              }
            });
            if(form_type !== 'edit'){
                apiFormData.append(`school_id`, inputData.school_id.id);
                apiFormData.append(`role_id`, inputData.role_id.id);
            }
            if((form_type === 'new'
                && formNewData.role_id
                && formNewData.role_id.id === 3)
                || (form_type === 'edit')
            ){
                //Class Id
                if(inputData.class_id
                    && inputData.class_id.length
                ){
                    inputData.class_id.forEach(value => {
                        apiFormData.append(`class_id[]`, value.id);
                    });
                }
            }
          }
          if(errorDetails[form_type].length > 0){
              setErrorData({...errorData, [form_type]: errorDetails[form_type]});
              setIsProcess({...isProcess, [form_type]: false});
              // setBtnDisabled({...isBtnDisabled, [form_type]: true});
          }else{
              //Send Data to Server
              var api = 'create';
              if(form_type === 'edit'){
                api = 'update';
                if(Object.keys(formUpdatedData).length === 0){
                  alertResponseMessage({alert_type: 3, message: "Nothing to Update"});
                  setIsProcess({...isProcess, [form_type]: false});
                  toggleModal('', form_type, false);
                  return;
                }else{
                  apiFormData.append(`user_id`, formEditData.id);
                }
              }              
              const updateFData = await post_api_call(`stakeholder/${api}`, apiFormData);
              if(updateFData.status === 200){
                  alertResponseMessage({alert_type: 1,
                                        message: form_type === 'new'
                                                ? "Admin Created Successfully"
                                                : "Information Updated Successfully"});
                  // setBtnDisabled({...isBtnDisabled, [form_type]: true});
                  setIsProcess({...isProcess, [form_type]: false});
                  //Close Modal
                  toggleModal('', form_type, false);
                  setIsLoading(true);
                  fetchStakeList();
              }else{
                  setIsProcess({...isProcess, [form_type]: false});
                  if(typeof updateFData.message !== "undefined"){
                      setErrorData({...errorData, [form_type]: [updateFData.message]});
                  }
              }
          }
          
      }catch(e){
          console.log(e);
          setErrorData({...errorData, [form_type]: ['Something Went Wrong, Please Try After Some Time']});
          setIsProcess({...isProcess, [form_type]: false});
          error_api_call({error: `admin|stakeholder|list|updateStake|${e}`});
      }
    }

    return (
        <>
        <div className="row">
            <div className="col-lg-12 text-end">
                <button className="btn btn-info mb-2" onClick={(e) => toggleModal(e, 'new', true, '', 'new')}>Add New Admin</button>
            </div>
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body table-responsive">
                    {/* <h4 className="card-title">Basic Table</h4> */}
                    {/* <p className="card-description"> Add class <code>.table</code></p> */}
                    <table className="table">
                      <thead>
                        <tr>
                          <th>User Id</th>
                          <th className="w-200p">First Name</th>
                          <th>Last Name</th>
                          <th>Role</th>
                          {props.userRoleIds.includes(2) && (
                            <th>School</th>
                          )}
                          <th>Created Date<br/>(DD/MM/YYYY)</th>
                          {/* <th>Is Approved?</th> */}
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading
                        ? 
                        <tr className="text-center"><td colSpan={8}><div className="p-4"><img alt="Loader" src={require('../../assets/images/Image-Loader.gif')} /></div></td></tr>
                        :
                        <>
                        {masterTableData.map((data, data_index) => (
                          <tr key={`tableData_${data_index}`}>
                            <td>{data.id}</td>
                            <td>{data.first_name}</td>
                            <td>{data.last_name}</td>
                            <td>{data.user_role}</td>
                            {/* <td>{data.is_reserved === 1 ? 'Yes' : 'No'}</td> */}
                            {props.userRoleIds.includes(2) && (
                                <td>{data.school_name}</td>
                            )}
                            <td>{moment(data.created_at).format("DD/MM/yyyy")}</td>
                            <td><label className={`badge ${data.status === 'Active' ? 'badge-success' : 'badge-danger'}`}>{data.status}</label></td>
                            <td>
                              <button onClick={(e) => loadViewModal(e, 'detail', data.id, data.role_id)} className="btn btn-sm btn-twitter" title="View Data"><i className="mdi mdi-eye"></i></button>
                              <button onClick={(e) => approveToggle(e, 'status_change', data.active_status_id, data.id, data.role_id)} className={`btn btn-sm ms-2 ${data.active_status_id === 1 ? 'btn-danger' : 'btn-success'}`} title="Activate/Inactive"><i className="mdi mdi-check-outline"></i></button>
                              <button onClick={(e) => loadViewModal(e, 'edit', data.id, data.role_id)} className={`btn btn-sm ms-2 btn-inverse-info`} title="Edit Data"><i className="mdi mdi-pencil"></i></button>
                            </td>
                          </tr>
                        ))}
                        </>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
        </div>
        {/* New Entry */}
        <ModalMaster
            isShow={modalToggle.new}
            _toggleModal={(e, state) => toggleModal(e, 'new', state)}
            modKey={activeEditForm}//new, edit
            formType={'new'}
            data={formNewData}
            title={masterTitle[activeEditForm]}
            _errorData={{...errorData}}
            _isProcess={{...isProcess}}
            // _isBtnDisabled={{...isBtnDisabled}}
            _updateInput= {(form_type, variable_type, input_type, field, value) => updateInput(form_type, variable_type, input_type, field, value)}
            _submitForm={(e, form_type) => updateStake(e, form_type)}
            _formData={[...masterFieldData]}
            selectOptions={{role_id:
                              {value: 'id',
                              label: 'role',
                              data: [...oppRoleOpt],
                            },
                            class_id: {value: 'id',
                              label: 'class_name',
                              data: [...oppClassOpt]},
                            school_id: {value: 'id',
                              label: 'school_name',
                              data: [...oppSchoolOpt]},
                          }}
            // doc_url={docUrlData}
        />
        {/* View Oppo Details */}
        <ModalMasterDetail
            data={detailedOppData}
            isShow={modalToggle.detail}
            _toggleModal={(e, state) => toggleModal(e, 'detail', state)}
            loader={modalLoader.detail}
            _formData={[{label: "Detail", fields: [...formViewData]}]}
            title={"Admin Details"}
        />
        {/* Edit Details */}
        <ModalMaster
            isShow={modalToggle.edit}
            loader={modalLoader.edit}
            _toggleModal={(e, state) => toggleModal(e, 'edit', state)}
            modKey={activeEditForm}//edit, edit
            formType={'edit'}
            data={formEditData}
            title={masterTitle[activeEditForm]}
            _errorData={{...errorData}}
            _isProcess={{...isProcess}}
            // _isBtnDisabled={{...isBtnDisabled}}
            _updateInput= {(form_type, variable_type, input_type, field, value) => updateInput(form_type, variable_type, input_type, field, value)}
            _submitForm={(e, form_type) => updateStake(e, form_type)}
            _formData={[...masterFieldData]}
            selectOptions={{
                            // school_id:
                            //   {value: 'id',
                            //   label: 'school_name',
                            //   data: [...oppSchoolOpt],
                            // },
                            class_id: {value: 'id',
                              label: 'class_name',
                              data: [...oppClassOpt]},
                          }}
            // doc_url={docUrlData}
        />
        </>
    )    
}

export default StakeholderList;