//New Entry Form Data
const fieldNewStake = [
    {colMd: "6", label: "First Name", required: true, type: "text", name: "first_name"},
    {colMd: "6", label: "Last Name", required: false, type: "text", name: "last_name"},
    {colMd: "6", label: "Role", required: true, type: "select", name: "role_id", placeholderData: ["Select Role"]},
    {colMd: "6", label: "Email", required: true, type: "email", name: "email"},
    {colMd: "6", label: "Password", required: true, type: "text", name: "password", tagline: "Minimum 8 Characters, Maximum 15 Characters"},
    {colMd: "6", label: "School/College/Organisation", required: true, type: "select", name: "school_id", placeholderData: ["Select School/College/Organisation"], tagline: "Allocate to Admin"}
    ];
const fieldNewTeacher = [...fieldNewStake,
    {colMd: "6", label: "Grade/Class/Course", required: true, type: "multi-select", name: "class_id", placeholderData: ["Select Grade/Class/Course"], tagline: "Allocate to Admin"},
    ];
const fieldValuesStake = {
//   first_name: 'Admin',
//   last_name: '',
//   role_id: {id: 4, role: 'Counsellor'},
//   email: 'admin@admin.com',
// school_id : {id: 1, school_name: "Grade 1"},
//   password: '12345678'
    first_name: '',
    last_name: '',
    role_id: '',
    email: '',
    school_id : '',
    password: ''
};
const fieldValuesTeacher = {...fieldValuesStake, class_id: ''};
//Validation: New
const fieldValidationStake = {
    first_name: 'required|max:50',
    last_name: 'optional|max:50',
    role_id: 'required||Role',
    email: 'required|email|max:150',
    // mobile: 'required|digit:|min:6|max:15||WhatsApp Number',
    password: 'required|min:8|max:15',
    school_id: 'required||School/College/Organisation'
};
const fieldValidationTeacher = {
    ...fieldValidationStake,
    class_id: 'required||Grade/Class/Course'
};
//Validation: Edit
const fieldValidEditStake = {
    first_name: 'required|max:50',
    last_name: 'optional|max:50',
    // role_id: 'required||Role',
    // email: 'required|email|max:150',
    // mobile: 'required|digit:|min:6|max:15||WhatsApp Number',
    password: 'optional|min:8|max:15',
    // school_id: 'required||School/College/Organisation'
};
const fieldValidEditTeacher = {
    ...fieldValidEditStake,
    class_id: 'required||Grade/Class/Course'
};
//View Form Data
const fieldStakeView = [
  {label: "Role", type: "string", value_key: "user_role"},
  {label: "First Name", type: "string", value_key: "first_name"},
  {label: "Last Name", type: "string", value_key: "last_name"},
  {label: "Email", type: "string", value_key: "email"},
  {label: "School", type: "string", value_key: "school_name"},
//   {label: "Class", type: "string", value_key: "class_name"}
];
const fieldStakeViewTeach = [
    ...fieldStakeView,
    // {label: "Class", type: "string", value_key: "class_name"}
    {label: "Class", type: "array", value_key: "admin_class_details", array_key: "class_name"},
  ];
//Edit
const fieldEditStake = [
    {colMd: "6", label: "First Name", required: true, type: "text", name: "first_name"},
    {colMd: "6", label: "Last Name", required: false, type: "text", name: "last_name"},
    // {colMd: "6", label: "Role", required: true, type: "select", name: "role_id", placeholderData: ["Select Role"]},
    // {colMd: "6", label: "Email", required: true, type: "email", name: "email"},
    {colMd: "6", label: "Password", required: false, type: "text", name: "password", tagline: "Minimum 8 Characters, Maximum 15 Characters"},
    // {colMd: "6", label: "School/College/Organisation", required: true, type: "select", name: "school_id", placeholderData: ["Select School/College/Organisation"], tagline: "Allocate to Admin"}
    ];
const fieldEditTeacher = [...fieldEditStake,
    {colMd: "6", label: "Grade/Class/Course", required: true, type: "multi-select", name: "class_id", placeholderData: ["Select Grade/Class/Course"], tagline: "Allocate to Admin"},
    ];
const data = {
    fieldNewStake,
    fieldValuesStake,
    fieldNewTeacher,
    fieldValuesTeacher,
    fieldValidationStake,
    fieldValidationTeacher,
    fieldStakeView,
    fieldStakeViewTeach,
    fieldEditStake,
    fieldEditTeacher,
    fieldValidEditTeacher,
    fieldValidEditStake
};

export default data;