import React, {useState, useEffect} from "react";
import {SetMetaData} from './../../helper/page-title';
import moment from 'moment';
import ValidationHelper from "../../helper/validation";
import {post_api_call,
        error_api_call,
        get_api_call_main,
        post_api_call_main
      } from './../../actions/action-creators/common';
import { alertResponseMessage } from "../../actions/action-creators/response";
//Modal
import ModalMaster from "../../modal/master";
import ModalMasterDetail from "../../modal/master-detail";
import FormDataHub from "../../data/formData/hub";
const {
    //Hub
    fieldHub,
    fieldValuesHub,
    fieldValidationHub,
    fieldHubView
} = FormDataHub;
const masterTitle = {
    new: 'New Opportunity Entry',
    edit: 'Edit Opportunity Entry',
    detail: 'Opportunity Details'
};
const oppModes = [
  {id:  1, label: 'Online'},
  {id:  2, label: 'Physical'},
  {id:  3, label: 'Hybrid'}
];
// const oppIsReserved =  [
//   {id:  1, label: 'Yes'},
//   {id:  2, label: 'No'}
// ];
const oppCurrencyType = [
  {id:  1, label: '₹'},
  {id:  2, label: '$'}
];
function HubList(){
    SetMetaData("Manage Opportunities", "", "");
    const [isLoading, setIsLoading] = useState(true);
    const [modalLoader, setModalLoader] = useState({edit: false, detail: false});
    const [modalToggle, setModalToggle] = useState({new: false,
                                                    edit: false,
                                                    detail: false});
    const [masterTableData, setMasterTableData] = useState([]);
    const [masterFieldData, setMasterFieldData] = useState([]);
    const [activeEditForm, setActiveForm] = useState("");
    // const [formDataMaster, setFormMasterData] = useState([]);
    const [errorData, setErrorData] = useState({new: [], edit: []});
    const [formNewData, setNewFormData] = useState({});
    const [formEditData, setEditFormData] = useState({});
    const [formUpdatedData, setUpdatedFormData] = useState({});
    const [isProcess, setIsProcess] = useState({new: false, edit: false});
    //Single Details
    const [detailedOppData, setDetailedOppData] = useState({});
    // const [isBtnDisabled, setBtnDisabled] = useState({new: true, edit: true});
    //Options Data
    const [oppTypeOpt, setOppTypeOpt] = useState([]);
    const [oppBenefitOpt, setOppBenefitOpt] = useState([]);
    const [oppAgeOpt, setOppAgeOpt] = useState([]);
    const [oppCourseOpt, setOppCourseOpt] = useState([]);
    const [oppFeeCharges, setOppFeeOpt] = useState([]);
    const [oppIsReserved, setOppReserve] = useState([]);
    //Get Data for Dropdown
    const fetchOpporList = async() => {
      try{
        const oppoApiData = await post_api_call('oppertunities', {});
        if(oppoApiData.status === 200){
          setMasterTableData(oppoApiData.data);
          setIsLoading(false);
        }
      }catch(e){
        error_api_call({error: `admin|hub|list|fetchOpporList|${e}`});
      }
    }
    //Get Data from APIs
    const fetchSelectList = async(type, url) => {
      try{
          const selectApiData = await get_api_call_main(url, {});
          var data = [];
          if(selectApiData.status === 200){
              data = selectApiData.data;
              switch (type) {
                  case 'opp_type':
                    // setOppTypeOpt([...selectApiData.data, {id: 0, label: 'Others'}]);
                    setOppTypeOpt([...data]);
                    break;
                  case 'opp_benefit':
                    setOppBenefitOpt(data);
                    break;
                  case 'opp_age':
                    setOppAgeOpt(data);
                    break;
                  case 'opp_course':
                    setOppCourseOpt(data);
                    break;
                  case 'fee':
                    setOppFeeOpt(data);
                    break;
                  case 'reserve':
                    setOppReserve(data);
                    break;
                  default: 
              }
          }
          return {
              data
          };
      }catch(e){
        error_api_call({error: `admin|hub|list|fetchSelectList|${e}`});
        return {data: []};
      }
    }
    useEffect(() => {
      //Initial API Data
      fetchOpporList();
    }, []);
    //View Data
    const loadViewModal = async(e, load_type, opportunity_id) => {
      e.preventDefault();
      try{
        //Page Loading
        toggleModal(e, load_type, true, '', load_type);
        setModalLoader({...modalLoader, [load_type]: true});
        const oppoApiData = await post_api_call_main(`opportunity/details/${opportunity_id}`, {fetch_type: 'admin'});
        if(oppoApiData.status === 200){
            if(load_type === 'detail'){
              // if(typeof oppoApiData.data.is_reserved !== "undefined"){
              //   oppoApiData.data.reserved_value = oppoApiData.data.is_reserved === 1 ? 'Yes' : 'No'
              // }
              if(oppoApiData.data.fee_id !== 6
                && oppoApiData.data.amount
              ){
                oppoApiData.data.amount = `${oppoApiData.data.currency_type === 1 ? '₹' : '$'} ${oppoApiData.data.amount}`;
              }
              setDetailedOppData(oppoApiData.data);
            }else{
              setUpdatedFormData({});
              //Opp Type
              var apiDataEdit = {...fieldValuesHub, ...oppoApiData.data};
              apiDataEdit.reservation = apiDataEdit.reservation_details;
              apiDataEdit.expiry_date = apiDataEdit.expiry_at;
              apiDataEdit.is_reserved = {id: apiDataEdit.is_reserved, label: apiDataEdit.reserved_value};
              apiDataEdit.fee_id = {id: apiDataEdit.fee_id, label: apiDataEdit.fee};
              // apiDataEdit.currency_type =  apiDataEdit.fee_id !== 6
              //                               ? {id: apiDataEdit.currency_type, label: apiDataEdit.currency_type === 1 ? '₹' : '$'}
              //                               : '';
              apiDataEdit.currency_type = oppoApiData.data.currency_type
                                        ? {id: oppoApiData.data.currency_type, label: oppoApiData.data.currency_type === 1 ? '₹' : '$'}
                                        : '';
              if(oppoApiData.data.type_details.length > 0){
                var typeIds = [];
                oppoApiData.data.type_details.forEach(label => {
                  typeIds.push({id: label.oppor_option_id, label: label.label});
                });
                apiDataEdit.opportunity_type_id = typeIds;
              }
              //Benefit
              if(oppoApiData.data.benefit_details.length > 0){
                var benefitIds = [];
                oppoApiData.data.benefit_details.forEach(label => {
                  benefitIds.push({id: label.oppor_option_id, label: label.label});
                });
                apiDataEdit.benefit_id = benefitIds;
              }
              //Age
              if(oppoApiData.data.age_details.length > 0){
                var ageIds = [];
                oppoApiData.data.age_details.forEach(label => {
                  ageIds.push({id: label.oppor_option_id, label: label.label});
                });
                apiDataEdit.eligible_age_id = ageIds;
              }
              //Course
              if(oppoApiData.data.course_details.length > 0){
                var courseIds = [];
                oppoApiData.data.course_details.forEach(label => {
                  courseIds.push({id: label.oppor_option_id, label: label.label});
                });
                apiDataEdit.eligible_course_id = courseIds;
              }
              //Mode
              if(oppoApiData.data.mode_details.length > 0){
                var modeIds = [];
                oppoApiData.data.mode_details.forEach(label => {
                  modeIds.push({id: label.oppor_option_id, label: label.label});
                });
                apiDataEdit.mode_id = modeIds;
              }
              setEditFormData({...apiDataEdit});
            }
        //   setOpenModal({true});
          setModalLoader({...modalLoader, [load_type]: false});
        }else{
          alertResponseMessage({alert_type: 2, message: "Error Loading Data"});
        }
      }catch(error){
        error_api_call({error: `admin|hub|list|loadViewModal|${error}`});
        alertResponseMessage({alert_type: 2, message: "Error Loading Data"});
      }
    }
    //Approve Toggle
    const approveToggle = async(e, type, is_active, opportunity_id) => {
        e.preventDefault();
        try{
          // status_change
          if (window.confirm(`${is_active === 1 ? 'Inactive' : 'Activate'} Opportunity?`)){
            const targetTag = e.target.tagName.toLowerCase();
            if(targetTag === 'i'){
                e.target.parentElement.disabled = true;
            }else{
                e.target.disabled = true;
            }
            const statusApiData = await post_api_call(`opportunity/status-change`, {id: opportunity_id, is_active: is_active === 2 ? 1 : 2});
            if(statusApiData.status === 200){
                fetchOpporList();
                alertResponseMessage({alert_type: 1, message: `Opportunity ${is_active === 1 ? 'Deactivated' : 'Activated'} Successfully!`});
            }
            if(targetTag === 'i'){
                e.target.parentElement.disabled = false;
            }else{
                e.target.disabled = false;
            }
          }
        }catch(e){
            const targetTag = e.target.tagName.toLowerCase();
            if(targetTag === 'i'){
                e.target.parentElement.disabled = false;
            }else{
                e.target.disabled = false;
            }
          error_api_call({error: `admin|hub|list|approveToggle|${e}`});
        }
    }
     //Update Input
    const updateInput = (form_type, state_var_type, input_type, field, value, field_type="", form_field="", index=0) => {
      try{
        // console.log(form_type, input_type, field, value)
          if(errorData[form_type].length){
            setErrorData({...errorData, [form_type]: []});
          }

          if(field === 'mobile_number'){
              if(value.length < 16){
                  value = value.replace(/[^0-9]/g, '');
              }else{
                  return false;
              }
          }else if(input_type === 'input'
              || input_type === 'textarea'
          ){
              // value = value.replace(/ /g, '');
              value = value.trimStart();
              // value = value.replace(/  /g, '');
              if(field === 'first_name'
                  || field === 'last_name'
              ){
                  value = value.replace(/[^a-zA-Z. ]/g, '')
                    .replace('  ', ' ');
              }
          }
          var updatedFormDetails = state_var_type === 'array' 
                            ? [] 
                            : state_var_type === 'object'
                                ? {}
                                : '';
          if(field_type === 'array'){
              const fieldData = form_type === 'new' ? {...formNewData} : {...formEditData};
              fieldData[form_field][index][field] = value;
              updatedFormDetails = {...fieldData};
              // setEditFormData({...fieldData});
          }else{
            updatedFormDetails = form_type === 'new' ? {...formNewData} : {...formEditData};
            updatedFormDetails[field] = value;
              // setEditFormData({
              //     ...formEditData,
              //     [field]: value
              // });
          }
          // if(field === 'show_image'){
          //   updatedFormDetails.show_image = true;
          // }
          if(form_type === 'new'){
            setNewFormData(updatedFormDetails);
          }else{
            setEditFormData(updatedFormDetails);
          }
          if(form_type === 'edit'){
            setUpdatedFormData({...formUpdatedData, [field]: value});
            // console.log(formUpdatedData, 'formUpdatedData')
          }
      }catch(e){
        console.log(e)
      }
    }
    // Toggle Modal
    const toggleModal = (e, modal_type, toggleState, index='', form_type='') => {
        try{
            if(toggleState){
                e.preventDefault();
            }
            if(toggleState){
                // var data = {};
                // var heading = '';
                var legendTitle = 'Details';
                switch (form_type) {
                    case 'new':
                        // heading = 'New Hub Details',
                        setNewFormData({...fieldValuesHub});
                        setMasterFieldData([{form_type: 'new', label: legendTitle, fields: [...fieldHub]}]);
                        break;
                    case 'edit':
                        // heading = 'Edit Hub Details',
                        // data = {...formDataMaster[index]};
                        // setEditFormData({...data});
                        setMasterFieldData([{form_type: 'edit', label: legendTitle, fields: [...fieldHub]}]);
                        break;
                    // case 'detail':
                    //     // heading = 'Hub Details',
                    //     data = {...formDataMaster[index]};
                    //     break;
                    default: 
                        break;
                }
                setErrorData({...errorData, [form_type]: []});
                setActiveForm(form_type);
            }
            setModalToggle({...modalToggle, [modal_type]: toggleState});
            if((form_type === 'new'
                || form_type === 'edit'
            ) && oppTypeOpt.length === 0){
              fetchSelectList('opp_type', 'opportunity/options/1');
              fetchSelectList('opp_benefit', 'opportunity/options/2');
              fetchSelectList('opp_age', 'opportunity/options/3?order_by=id');
              fetchSelectList('opp_course', 'opportunity/options/4');
              fetchSelectList('fee', 'opportunity/options/6');
              fetchSelectList('reserve', 'opportunity/options/7');
            }
        }catch(error){
            console.log(error)
        }
    }
    //Submit Details
    const updateHub = async(e, form_type='') => {
      try{
          e.preventDefault();
          setErrorData({...errorData, [form_type]: []});
          setIsProcess({...isProcess, [form_type]: true});
          var errorDetails = {
            new: [],
            edit: []
          };
          var apiFormData = new FormData();
          //Validation
          var validation = [];
          var inputData = {};
          switch (form_type) {
              case 'new':
                inputData = {...formNewData};
                break;
              case 'edit':
                inputData = {...formEditData};
                break;
              default:
          }
          console.log(inputData, 'inputDatainputDatainputData')
          validation = ValidationHelper.validation(inputData, fieldValidationHub).error;
          if(validation.length){
            errorDetails[form_type] = validation;
          }
          //Type
          if(!inputData.opportunity_type_id.length
            && !inputData.opportunity_name
          ){
            errorDetails[form_type].push('Either Opportunity Type or Other-Opportunity Type is Required');
          }
          //Course
          if(!inputData.eligible_course_id.length
            && !inputData.course_name
          ){
            errorDetails[form_type].push('Eligibility By Course or Other-Eligibility By Course is Required');
          }
          //Age
          if(!inputData.eligible_age_id.length
            && !inputData.age_name
          ){
            errorDetails[form_type].push('Eligibility Age or Other-Eligibility Age is Required');
          }
          //Check if Charges: Discounted/Paid is selected , amount and currency type should be there
          if(inputData.fee_id.id === 7
            || inputData.fee_id.id === 8
          ){
            if(!inputData.currency_type){
              errorDetails[form_type].push("Currency Type is required");
            }else if(form_type === 'new'){
              apiFormData.append(`currency_type`, inputData.currency_type.id);
            }
            
            if(!inputData.amount
              || inputData.amount === '0'
            ){
              errorDetails[form_type].push("Fees/Charges Amount is required");
            }
          }
          if(errorDetails[form_type].length === 0){
            if(form_type === 'edit'){
              inputData = {...formUpdatedData};
              if(inputData.currency_type){
                apiFormData.append(`currency_type`, inputData.currency_type.id);
              }
              if(formEditData.fee_id.id !== 6
                && inputData.amount
              ){//Not Free
                apiFormData.append(`amount`, inputData.amount);
              }else if(inputData.fee_id
                && inputData.fee_id.id === 6
              ){
                apiFormData.append(`amount`, 0);
              }
            }else{
              apiFormData.append(`amount`, inputData.amount);
            }
            var fields = ['title',
                          'opportunity_name',
                          'description',
                          'eligibility',
                          'duration',
                          'reservation',
                          'expiry_date',
                          'apply_link',
                          'priority',
                          'course_name',
                          'benefit_name',
                          'age_name',
                          'imp_rule',
                          'key_benefit',
                          // 'amount'
                        ];
            fields.forEach(field => {
              if(form_type === 'new'
                || (form_type === 'edit'
                    && typeof inputData[field] !== "undefined")
              ){
                apiFormData.append(field, inputData[field]);
              }
            });
            // Logo
            if(inputData.logo){
              apiFormData.append('logo', inputData.logo);
            }
            // const oppoTypeIds = [];
            // const oppoBenefitIds = [];
            // const oppoModeIds = [];
            const oppoCourseIds = [];
            const oppoAgeIds = [];
            //Opportunity Type
            if(inputData.opportunity_type_id
              && inputData.opportunity_type_id.length
            ){
              inputData.opportunity_type_id.forEach(value => {
                apiFormData.append(`opportunity_type_id[]`, value.id);
              });
            }
            //Benefit
            if(inputData.benefit_id
              && inputData.benefit_id.length
            ){
              inputData.benefit_id.forEach(value => {
                // oppoBenefitIds.push(value.id);
                apiFormData.append(`benefit_id[]`, value.id);
              });
            }
            //Age
            if(inputData.eligible_age_id
              && inputData.eligible_age_id.length
            ){
              inputData.eligible_age_id.forEach(value => {
                oppoAgeIds.push(value.id);
                apiFormData.append(`eligible_age_id[]`, value.id);
              });
            }
            //Course
            if(inputData.eligible_course_id
              && inputData.eligible_course_id.length
            ){
              inputData.eligible_course_id.forEach(value => {
                oppoCourseIds.push(value.id);
                apiFormData.append(`eligible_course_id[]`, value.id);
              });
            }
            //Mode
            if(inputData.mode_id
              && inputData.mode_id.length
            ){
              inputData.mode_id.forEach(value => {
                // oppoModeIds.push(value.id);
                apiFormData.append(`mode_id[]`, value.id);
              });
            }
            if(inputData.is_reserved){
              apiFormData.append(`is_reserved`, inputData.is_reserved.id);
            }
            // apiFormData.append('data', JSON.stringify(inputData));
            //Validation
            //Check if For Age and Course, All is present with any other Options
            // Age
            if(oppoAgeIds.indexOf(4) !== -1
              && oppoAgeIds.length > 1
            ){
              errorDetails[form_type].push("Eligibility Age: No Other selection is allowed when 'All' is Selected");
            }else if(oppoAgeIds.indexOf(4) !== -1
              && inputData.age_name
            ){
              errorDetails[form_type].push("Other-Eligibility Age: Leave blank as 'All' is Selected for Eligibility Age");
            }
            // Course
            if(oppoCourseIds.indexOf(5) !== -1
              && oppoCourseIds.length > 1
            ){
              errorDetails[form_type].push("Eligibility By Course: No Other selection is allowed when 'All' is Selected");
            }else if(oppoCourseIds.indexOf(5) !== -1
              && inputData.course_name
            ){
              errorDetails[form_type].push("Other-Eligibility By Course: Leave blank as 'All' is Selected for Eligibility By Course");
            }
            if(inputData.fee_id){
              apiFormData.append(`fee_id`, inputData.fee_id.id);
            }
          }
          if(errorDetails[form_type].length > 0){
              setErrorData({...errorData, [form_type]: errorDetails[form_type]});
              setIsProcess({...isProcess, [form_type]: false});
              // setBtnDisabled({...isBtnDisabled, [form_type]: true});
          }else{
              //Send Data to Server
              var api = 'create';
              if(form_type === 'edit'){
                api = 'update';
                // apiFormData.append('opportunity_id', props.data.ref_id);
                if(Object.keys(formUpdatedData).length === 0){
                  alertResponseMessage({alert_type: 3, message: "Nothing to Update"});
                  setIsProcess({...isProcess, [form_type]: false});
                  toggleModal('', form_type, false);
                  return;
                }else{
                  apiFormData.append(`opportunity_id`, formEditData.id);
                }
              }              
              const updateOppor = await post_api_call(`opportunity/${api}`, apiFormData);
              if(updateOppor.status === 200){
                  alertResponseMessage({alert_type: 1, message: "Information Updated Successfully"});
                  // setBtnDisabled({...isBtnDisabled, [form_type]: true});
                  setIsProcess({...isProcess, [form_type]: false});
                  //Close Modal
                  toggleModal('', form_type, false);
                  setIsLoading(true);
                  fetchOpporList();
              }else{
                  setIsProcess({...isProcess, [form_type]: false});
                  if(typeof updateOppor.message !== "undefined"){
                      setErrorData({...errorData, [form_type]: [updateOppor.message]});
                  }
              }
          }
          
      }catch(e){
          console.log(e);
          setErrorData({...errorData, [form_type]: ['Something Went Wrong, Please Try After Some Time']});
          setIsProcess({...isProcess, [form_type]: false});
          error_api_call({error: `admin|hub|list|updateHub|${e}`});
      }
    }

    return (
        <>
        <div className="row">
            <div className="col-lg-12 text-end">
                <button className="btn btn-info mb-2" onClick={(e) => toggleModal(e, 'new', true, '', 'new')}>Add New Opportunity</button>
            </div>
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body table-responsive">
                    {/* <h4 className="card-title">Basic Table</h4> */}
                    {/* <p className="card-description"> Add class <code>.table</code></p> */}
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Oppor. Id</th>
                          <th className="w-200p">Title</th>
                          <th>Priority</th>
                          {/* <th>Is Reserved?</th> */}
                          <th>Expiry Date<br/>(DD/MM/YYYY)</th>
                          <th>Created Date<br/>(DD/MM/YYYY)</th>
                          {/* <th>Is Approved?</th> */}
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading
                        ? 
                        <tr className="text-center"><td colSpan={8}><div className="p-4"><img alt="Loader" src={require('../../assets/images/Image-Loader.gif')} /></div></td></tr>
                        :
                        <>
                        {masterTableData.map((data, data_index) => (
                          <tr key={`tableData_${data_index}`}>
                            <td>{data.id}</td>
                            <td>{data.title}</td>
                            <td>{data.priority}</td>
                            {/* <td>{data.is_reserved === 1 ? 'Yes' : 'No'}</td> */}
                            <td>{moment(data.expiry_at).format("DD/MM/yyyy")}</td>
                            <td>{moment(data.created_at).format("DD/MM/yyyy")}</td>
                            {/* <td><label className={`badge ${data.is_active === 1 ? 'badge-success' : 'badge-danger'}`}>{data.is_active === 1 ? 'Yes' : 'No'}</label></td> */}
                            <td><label className={`badge ${data.status === 'Active' ? 'badge-success' : 'badge-danger'}`}>{data.status}</label></td>
                            <td>
                              <button onClick={(e) => loadViewModal(e, 'detail', data.id)} className="btn btn-sm btn-twitter" title="View Data"><i className="mdi mdi-eye"></i></button>
                              <button onClick={(e) => approveToggle(e, 'status_change', data.active_status_id, data.id)} className={`btn btn-sm ms-2 ${data.active_status_id === 1 ? 'btn-danger' : 'btn-success'}`} title="Activate/Inactive"><i className="mdi mdi-check-outline"></i></button>
                              <button onClick={(e) => loadViewModal(e, 'edit', data.id)} className={`btn btn-sm ms-2 btn-inverse-info`} title="Edit Data"><i className="mdi mdi-pencil"></i></button>
                            </td>
                          </tr>
                        ))}
                        </>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
        </div>
        {/* New Entry */}
        <ModalMaster
            isShow={modalToggle.new}
            _toggleModal={(e, state) => toggleModal(e, 'new', state)}
            modKey={activeEditForm}//new, edit
            formType={'new'}
            data={formNewData}
            title={masterTitle[activeEditForm]}
            _errorData={{...errorData}}
            _isProcess={{...isProcess}}
            // _isBtnDisabled={{...isBtnDisabled}}
            _updateInput= {(form_type, variable_type, input_type, field, value) => updateInput(form_type, variable_type, input_type, field, value)}
            _submitForm={(e, form_type) => updateHub(e, form_type)}
            _formData={[...masterFieldData]}
            selectOptions={{opportunity_type_id:
                              {value: 'id',
                              label: 'label',
                              data: [...oppTypeOpt],
                              // max: 2,
                              // format: {req: true, key: 'label'}
                            },
                            benefit_id: {value: 'id',
                              label: 'label',
                              data: [...oppBenefitOpt]},
                            eligible_age_id: {value: 'id',
                              label: 'label',
                              data: [...oppAgeOpt]},
                            eligible_course_id: {value: 'id',
                              label: 'label',
                              data: [...oppCourseOpt]},
                            mode_id: {value: 'id',
                              label: 'label',
                              data: [...oppModes]},
                            is_reserved: {value: 'id',
                              label: 'label',
                              data: [...oppIsReserved]},
                            fee_id: {value: 'id',
                              label: 'label',
                              data: [...oppFeeCharges]},
                            currency_type: {value: 'id',
                              label: 'label',
                              data: [...oppCurrencyType]},
                          }}
            // doc_url={docUrlData}
        />
        {/* View Oppo Details */}
        <ModalMasterDetail
            data={detailedOppData}
            isShow={modalToggle.detail}
            _toggleModal={(e, state) => toggleModal(e, 'detail', state)}
            loader={modalLoader.detail}
            _formData={[{label: "Detail", fields: [...fieldHubView]}]}
            title={"Opportunity Details"}
        />
        {/* Edit Oppo Details */}
        <ModalMaster
            isShow={modalToggle.edit}
            loader={modalLoader.edit}
            _toggleModal={(e, state) => toggleModal(e, 'edit', state)}
            modKey={activeEditForm}//edit, edit
            formType={'edit'}
            data={formEditData}
            title={masterTitle[activeEditForm]}
            _errorData={{...errorData}}
            _isProcess={{...isProcess}}
            // _isBtnDisabled={{...isBtnDisabled}}
            _updateInput= {(form_type, variable_type, input_type, field, value) => updateInput(form_type, variable_type, input_type, field, value)}
            _submitForm={(e, form_type) => updateHub(e, form_type)}
            _formData={[...masterFieldData]}
            selectOptions={{opportunity_type_id:
                              {value: 'id',
                              label: 'label',
                              data: [...oppTypeOpt],
                              // max: 2,
                              // format: {req: true, key: 'label'}
                            },
                            benefit_id: {value: 'id',
                              label: 'label',
                              data: [...oppBenefitOpt]},
                            eligible_age_id: {value: 'id',
                              label: 'label',
                              data: [...oppAgeOpt]},
                            eligible_course_id: {value: 'id',
                              label: 'label',
                              data: [...oppCourseOpt]},
                            mode_id: {value: 'id',
                              label: 'label',
                              data: [...oppModes]},
                            is_reserved: {value: 'id',
                              label: 'label',
                              data: [...oppIsReserved]},
                            fee_id: {value: 'id',
                              label: 'label',
                              data: [...oppFeeCharges]},
                            currency_type: {value: 'id',
                              label: 'label',
                              data: [...oppCurrencyType]},
                          }}
            // doc_url={docUrlData}
        />
        </>
    )    
}

export default HubList;