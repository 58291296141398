const roleData = [
    {id: 2, role: 'Super Admin'},
    {id: 3, role: 'Teacher'},
    {id: 4, role: 'Counsellor'},
    {id: 5, role: 'Principal'}
];
const roleStakeData = [
    {id: 3, role: 'Teacher'},
    {id: 4, role: 'Counsellor'},
    {id: 5, role: 'Principal'}
];
const studentTypeOpp = [
    {id: 1, member: "Glosco"},
    {id: 0, member: "Port"}
];
const portCompleOpp = [
    {id: '0,20', percentage: "0 - 20%"},
    {id: '21,40', percentage: "21 - 40%"},
    {id: '41,60', percentage: "41 - 60%"},
    {id: '61,80', percentage: "61 - 80%"},
    {id: '81,100', percentage: "81 - 100%"}
];
const portExpiryOpp = [
    {id: 0, validity: "Expired"},
    {id: 7, validity: "<7 days left"},
    {id: 15, validity: "<15 days left"},
];
const activeStatusOpp = [
    {id: 1, status: "Active"},
    {id: 2, status: "Inactive"}
];
const portSectionOpp = [
    {section: 'LAX', id: 11},
    {section: 'LOR', id: 15},
    {section: 'Glosco', id: 7},
    {section: 'Photograph', id: 2},
    {section: 'Headline', id: 3},
    {section: 'Short Profile', id: 4},
    {section: 'Unique Competencies and Career Mapping', id: 5},
    {section: 'Education', id: 6},
    {section: 'Experience', id: 8},
    {section: 'Projects, Assignments, Researches...', id: 9},
    {section: 'Sports, Games, Art...', id: 10},
    {section: 'Leadership, Honours and Awards', id: 12},
    {section: 'Career Skills', id: 13},
    {section: 'Personal Skills', id: 14},
    // {section: 'social', id: 16}
];

const portSecTitle = {
    1: 'General',
    2: 'Photograph',
    3: 'Headline',
    4: 'Short Profile',
    5: 'Unique Competencies and Career Mapping',
    6: 'Education',
    7: 'Glosco',
    8: 'Experience',
    9: 'Projects, Assignments, Researches...',
    10: 'Sports, Games, Art...',
    11: 'LAX',
    12: 'Leadership, Honours and Awards',
    13: 'Career Skills',
    14: 'Personal Skills',
    15: 'LOR',
    // 16: 'SS'
};

const filterOperator = [
    {id: 'OR', oper: "OR"},
    {id: 'AND', oper: "AND"},
];

export {roleData,
    roleStakeData,
    studentTypeOpp,
    portCompleOpp,
    portExpiryOpp,
    activeStatusOpp,
    portSectionOpp,
    portSecTitle,
    filterOperator
};