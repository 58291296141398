import React, {useState, useEffect} from "react";
import {SetMetaData} from './../../helper/page-title';
import moment from 'moment';
import {post_api_call, get_api_call, error_api_call, get_api_call_main} from './../../actions/action-creators/common';
import { alertResponseMessage } from "../../actions/action-creators/response";
import ViewFellowModal from "../../modal/fellow/view";
import EditFellowModal from "../../modal/fellow/edit";
// import './../../assets/css/pages/fellow.css';

function FellowList(){
    SetMetaData("Manage Fellows", "", "");
    const [fellowData, setFellowData] = useState([]);
    const [openModal, setOpenModal] = useState({view: false, edit: false});
    const [fellowDetails, setFellowDetails] = useState({});
    // const [fellowLoadDetails, setLoadDetails] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    // Edit
    const [editFellowId, setEditFellowId] = useState('');
    const [formEditData, setEditFormData] = useState({});
    const [errorEditData, setEditErrorData] = useState({});
    
    const [countryData, setCountryData] = useState([]);
    const [passionData, setPassionData] = useState([]);
    const [programData, setProgramData] = useState([]);
    //Get Data for Fellow
    const fetchFellowList = async() => {
      try{
        const fellowApiData = await post_api_call('fellows', {});
        if(fellowApiData.status === 200){
          setFellowData(fellowApiData.data);
          setIsLoading(false);
        }
      }catch(e){
        error_api_call({error: `admin|fellow|list|fetchFellowList|${e}`});
      }
    }
    //Get Data for Country
    const fetchCountryList = async() => {
        try{
            if(countryData.length === 0){
                const countryApiData = await get_api_call_main('countries', {});
                if(countryApiData.status === 200){
                    setCountryData(countryApiData.data);
                }
            }
        }catch(e){
            error_api_call({error: `fellow|list|fetchCountryList|${e}`});
        }
    }
    //Get Data for Passion Scores & Program
    const fetchPassionList = async() => {
        try{
            if(passionData.length === 0){
                const selectApiData = await get_api_call_main('passion-scores', {});
                if(selectApiData.status === 200){
                    setPassionData(selectApiData.data);
                }
            }
        }catch(e){
			error_api_call({error: `fellow|list|fetchPassionList|${e}`});
        }
    }
    //Get Data for Program
    const fetchProgramList = async() => {
        try{
            if(programData.length === 0){
                const selectApiData = await get_api_call_main('ifspd-program', {});
                if(selectApiData.status === 200){
                    setProgramData(selectApiData.data);
                }
            }
        }catch(e){
			error_api_call({error: `fellow|list|fetchProgramList|${e}`});
        }
    }
    useEffect(() => {
      //Initial API Data
      fetchFellowList();
    }, []);
    //View Data
    const loadViewModal = async(e, load_type, fellow_id) => {
      e.preventDefault();
      try{
        const fellowApiData = await get_api_call(`fellow/${fellow_id}`, {});
        console.log(fellowApiData, 'fellowApiData')
        if(fellowApiData.status === 200){
            if(load_type === 'view'){
                setFellowDetails(fellowApiData.data)
            }else{
                setEditFellowId(fellow_id);
                //Exp Details
                var expDetails = fellowApiData.data.experience_details;
                var eduDetails = fellowApiData.data.education_details;
                var awardDetails = fellowApiData.data.award_details;
                var skillDetails = fellowApiData.data.unique_skills;
                var expData = [];
                var eduData = [];
                var awardData = [];
                var skillData = [];
                //Exp Details
                expDetails.forEach(exp_d => {
                  expData.push({...exp_d,
                              start_year: exp_d.start_year === null ? '' : {id: exp_d.start_year, year: exp_d.start_year},
                              end_year:  exp_d.end_year === null ? '' : {id: exp_d.end_year, year: exp_d.end_year},
                              // is_present: exp_d.end_year === null ? true : false});
                              is_present: (exp_d.start_year !== null && exp_d.end_year === null) ? true : false});
                });
                if(expDetails.length < 5){
                  for(let i=expDetails.length+1; i<6; i++){
                    expData.push({designation: '', organization: '', achievement: '', start_year : '', end_year: '', is_present: false});
                  }
                }
                // Edu Details
                eduDetails.forEach(edu_d => {
                  eduData.push({...edu_d,
                              start_year: edu_d.start_year === null ? '' : {id: edu_d.start_year, year: edu_d.start_year},
                              end_year: edu_d.end_year === null ? '' : {id: edu_d.end_year, year: edu_d.end_year},
                              is_present: (edu_d.start_year !== null && edu_d.end_year === null) ? true : false});
                });
                if(eduDetails.length < 3){
                  for(let i=eduDetails.length+1; i<4; i++){
                    eduData.push({institute_name: '', program_name: '', achievement: '', start_year : '', end_year: '', is_present: false});
                  }
                }
                // Award
                awardDetails.forEach(award_d => {
                  awardData.push({...award_d,
                              year: {id: award_d.year, year: award_d.year}
                            });
                });
                if(awardDetails.length < 3){
                  for(let i=awardDetails.length+1; i<4; i++){
                    awardData.push({institute_name: '', award_name: '', why_awarded: '', year : ''});
                  }
                }
                // passion
                skillDetails.forEach(skill => {
                  skillData.push({
                              passion_id: {id: skill.passion_id, title: skill.title}
                            });
                });
                
                setEditFormData({...fellowApiData.data,
                                experience_details: expData,
                                education_details: eduData,
                                award_details: awardData,
                                unique_skills: skillData
                              });
            }
        //   setOpenModal({true});
            handleClose(load_type, true);
        }
      }catch(e){
        error_api_call({error: `admin|fellow|list|loadViewModal|${e}`});
      }
    }
    //Approve Toggle
    const approveToggle = async(e, type, is_approved, fellow_id) => {
        e.preventDefault();
        try{
          if (window.confirm(`${is_approved === 0 ? 'Approve' : 'Disapprove'} Fellow?`)){
            const targetTag = e.target.tagName.toLowerCase();
            if(targetTag === 'i'){
                e.target.parentElement.disabled = true;
            }else{
                e.target.disabled = true;
            }
            const fellowApiData = await post_api_call(`fellow-approval`, {user_id: fellow_id, is_approved: is_approved === 0 ? 1 : 0});
            if(fellowApiData.status === 200){
                fetchFellowList();
                alertResponseMessage({alert_type: 1, message: `Fellow ${is_approved === 0 ? 'approved' : 'disapproved'} Successfully!`});
            }
            if(targetTag === 'i'){
                e.target.parentElement.disabled = false;
            }else{
                e.target.disabled = false;
            }
          }
        }catch(e){
            const targetTag = e.target.tagName.toLowerCase();
            if(targetTag === 'i'){
                e.target.parentElement.disabled = false;
            }else{
                e.target.disabled = false;
            }
          error_api_call({error: `admin|fellow|list|approveToggle|${e}`});
        }
    }
    // Handle Modal Show-Hide
    const handleClose = (modal_name, state) => {
        try{
            setOpenModal({...openModal, [modal_name]: state})
        }catch(e){
            
        }
    };
     //Update Input
    const updateInput = (input_type, field, value, field_type="", form_field="", index=0) => {
      try{
          if(errorEditData.length){
            setEditErrorData([]);
          }

          console.log(input_type, field, value)
          if(field === 'mobile_number'){
              if(value.length < 16){
                  value = value.replace(/[^0-9]/g, '');
              }else{
                  return false;
              }
          }else if(input_type === 'input'
              || input_type === 'textarea'
          ){
              // value = value.replace(/ /g, '');
              value = value.trimStart();
              // value = value.replace(/  /g, '');
              if(field === 'first_name'
                  || field === 'last_name'
              ){
                  value = value.replace(/[^a-zA-Z. ]/g, '')
                    .replace('  ', ' ');
              }
          }

          if(field_type === 'array'){
              const fieldData = {...formEditData};
              fieldData[form_field][index][field] = value;
              setEditFormData({...fieldData});
          }else{
              setEditFormData({
                  ...formEditData,
                  [field]: value
              });
          }
          console.log(formEditData, 'formEditData')
      }catch(e){

      }
    }
    //Edit Data
    const updateFellowData = async(event) => {
      try{
        event.preventDefault();
        console.log(event)
  //     //Validation
  //     setErrorData([]);
  //     setIsProcess(true);
  //     var errorDetails = [];
  //     //Image
  //     console.log(previewPicImg, 'previewPicImg')
  //     if(previewPicImg === null){
  //         errorDetails.push('Photograph is required');
  //     }
  //     const fieldFormData = {...formData,
  //                             country_id: formData.country_id.id,
  //                             phone_code_id: formData.phone_code_id.id
  //                         };
  //                         console.log(fieldFormData,'fieldFormDatafieldFormDatafieldFormData')
  //     const validationRules = {
  //         first_name: 'required|max:100',
  //         last_name: 'optional|max:100',
  //         country_id: 'required||Country',
  //         dob: 'required||Date of Birth',
  //         email: 'required|email|max:150',
  //         phone_code_id: 'required||Mobile Country Code',
  //         mobile_number: 'required|digit:|min:6|max:15',
  //         position: 'required|max:150||Who you are (Point 4)',
  //         headline: 'required|max:250||A headline of who you are (Point 5)',
  //         imp_experience: 'optional|max:250||Most important work experience (Point 5)',
  //         imp_education: 'optional|max:250||Most important education (Point 5)'
  //     };
  //     // console.log(formData, 'formData');
  //     const validation = ValidationHelper.validation(fieldFormData, validationRules).error;
  //     if(validation.length){
  //         errorDetails = errorDetails.concat(validation);
  //     }
  //     var apiFormData = new FormData();
  //     // var expData = [];
  //     // Work Experience
  //     initialFormData.experience.forEach((exp, exp_indx) => {
  //         var expDetails = fieldFormData.experience[exp_indx];
  //         if(expDetails.designation || expDetails.organization || expDetails.achievement){
  //             const expValidationRules = {
  //                 designation: `required|max:100||Work Experience ${exp_indx + 1}: Designation`,
  //                 organization: `required|max:100||Work Experience ${exp_indx + 1}: Organization Name`,
  //                 achievement: `required|max:250||Work Experience ${exp_indx + 1}: Key Responsibilities or Achievements`,
  //                 start_year: `required||Work Experience ${exp_indx + 1}: Start Year`
  //             };
  //             const validationExp = ValidationHelper.validation(expDetails, expValidationRules).error;
  //             if(validationExp.length){
  //                 errorDetails = errorDetails.concat(validationExp);
  //             }
              
  //             if(expDetails.is_present === false){
  //                 if(expDetails.end_year === ''){
  //                     errorDetails.push(`Work Experience ${exp_indx + 1}: End Year is Required`);
  //                 }else if(expDetails.start_year !== '' && expDetails.start_year.id > expDetails.end_year.id){
  //                     errorDetails.push(`Work Experience ${exp_indx + 1}: Start Year cannot be greater than End Year`);
  //                 }
  //             }
  //             if(!errorDetails.length){
  //                 var expEndYear = expDetails.is_present === true ? null : expDetails.end_year.id;
  //                 apiFormData.append(`experience[${exp_indx}][designation]`, expDetails.designation);
  //                 apiFormData.append(`experience[${exp_indx}][organization]`, expDetails.organization);
  //                 apiFormData.append(`experience[${exp_indx}][achievement]`, expDetails.achievement);
  //                 apiFormData.append(`experience[${exp_indx}][start_year]`, expDetails.start_year.id);
  //                 apiFormData.append(`experience[${exp_indx}][end_year]`, expEndYear);
  //                 apiFormData.append(`experience[${exp_indx}][is_present]`, expDetails.is_present);
  //             }
  //         }
  //     });
  //     //Education
  //     var isEduData = false;
  //     // var eduData = [];
  //     initialFormData.education.forEach((edu, edu_indx) => {
  //         var eduDetails = fieldFormData.education[edu_indx];
  //         if(eduDetails.institute_name || eduDetails.program_name || eduDetails.achievement){
  //             isEduData = true;
  //             const eduValidationRules = {
  //                 institute_name: `required|max:100||Education ${edu_indx + 1}: College/Institution Name`,
  //                 program_name: `required|max:100||Education ${edu_indx + 1}: Name of the Program`,
  //                 achievement: `optional|max:250||Education ${edu_indx + 1}: Key Achievement or Grade`,
  //                 start_year: `required||Education ${edu_indx + 1}: Start Year`
  //             };
  //             const validationEdu = ValidationHelper.validation(eduDetails, eduValidationRules).error;
  //             if(validationEdu.length){
  //                 errorDetails = errorDetails.concat(validationEdu);
  //             }
  //             console.log(eduDetails.is_present, 'eduDetails.is_present')
  //             if(eduDetails.is_present === false){
  //                 if(eduDetails.end_year === ''){
  //                     errorDetails.push(`Education ${edu_indx + 1}: End Year is Required`);
  //                 }else if (eduDetails.start_year !== '' && eduDetails.start_year.id > eduDetails.end_year.id){
  //                     errorDetails.push(`Education ${edu_indx + 1}: Start Year cannot be greater than End Year`);
  //                 }
  //             }
  //             if(!errorDetails.length){
  //                 var eduEndYear = eduDetails.is_present === true ? null : eduDetails.end_year.id;
  //                 apiFormData.append(`education[${edu_indx}][institute_name]`, eduDetails.institute_name);
  //                 apiFormData.append(`education[${edu_indx}][program_name]`, eduDetails.program_name);
  //                 apiFormData.append(`education[${edu_indx}][achievement]`, eduDetails.achievement);
  //                 apiFormData.append(`education[${edu_indx}][start_year]`, eduDetails.start_year.id);
  //                 apiFormData.append(`education[${edu_indx}][end_year]`, eduEndYear);
  //                 apiFormData.append(`education[${edu_indx}][is_present]`, eduDetails.is_present);
  //             }
  //         }
  //     });
  //     if(!isEduData){
  //         errorDetails.push(`Atleast 1 Education Detail Required`);
  //     }
  //     //Award
  //     // var awardData = [];
  //     initialFormData.award.forEach((award, award_indx) => {
  //         var awardDetails = fieldFormData.award[award_indx];
  //         if(awardDetails.institute_name || awardDetails.award_name || awardDetails.why_awarded){
  //             const awardValidationRules = {
  //                 institute_name: `required|max:100||Honors and Awards ${award_indx + 1}: Institution Name`,
  //                 award_name: `required|max:100||Honors and Awards ${award_indx + 1}: Name of the Award`,
  //                 why_awarded: `required|max:100||Honors and Awards ${award_indx + 1}: Why was this awarded to you?`,
  //                 year: `required||Honors and Awards ${award_indx + 1}: Year`
  //             };
  //             const validationAward = ValidationHelper.validation(awardDetails, awardValidationRules).error;
  //             if(validationAward.length){
  //                 errorDetails = errorDetails.concat(validationAward);
  //             }
  //             if(!errorDetails.length){
  //                 apiFormData.append(`award[${award_indx}][institute_name]`, awardDetails.institute_name);
  //                 apiFormData.append(`award[${award_indx}][award_name]`, awardDetails.award_name);
  //                 apiFormData.append(`award[${award_indx}][why_awarded]`, awardDetails.why_awarded);
  //                 apiFormData.append(`award[${award_indx}][year]`, awardDetails.year.id);
  //             }
  //         }
  //     });
  //     // Unique Skills
  //     var passionData = [];
  //     initialFormData.passion.forEach((passion, passion_indx) => {
  //         var passionDetails = fieldFormData.passion[passion_indx];
  //         if(passionDetails.passion_id !== ''){
  //             if(passionData.indexOf(passionDetails.passion_id.id) !== -1){
  //                 errorDetails.push(`Unique Skills: 2 Passion Scores cannot be same`);
  //             }
  //             passionData.push(passionDetails.passion_id.id);
  //             apiFormData.append(`passion[${passion_indx}]`, passionDetails.passion_id.id);
  //         }
  //     });
  //     if(passionData.length > 0 && passionData.length < 3){
  //         errorDetails.push(`Unique Skills: All 3 Passion Scores Required`);
  //     }
  //     // IFSPD Program and Year
  //     if(fieldFormData.ifspd_program_id === ''){
  //         errorDetails.push(`IFSPD Program is Required`);
  //     }else{
  //         apiFormData.append(`ifspd_program_id`, fieldFormData.ifspd_program_id.id);
  //     }
  //     if(fieldFormData.ifspd_program_year === ''){
  //         errorDetails.push(`IFSPD Program Year is Required`);
  //     }else{
  //         apiFormData.append(`ifspd_program_year`, fieldFormData.ifspd_program_year.id);
  //     }
      
  //     console.log('validation', errorDetails)
  //     if(errorDetails.length > 0){
  //         setErrorData(errorDetails);
  //         setIsProcess(false);
  //     }else{
  //         //Send Data to Server
  //         delete fieldFormData.education;
  //         delete fieldFormData.experience;
  //         delete fieldFormData.award;
  //         delete fieldFormData.passion;
  //         delete fieldFormData.ifspd_program_id;
  //         delete fieldFormData.ifspd_program_year;
  //         apiFormData.append('profile_pic', previewPicImg.data, 'Profile Pic');
  //         // apiFormData.append('general_data', fieldFormData);
  //         for (var key in fieldFormData) {
  //             console.log(key);
  //             if (fieldFormData.hasOwnProperty(key)) {
  //                 apiFormData.append(key, fieldFormData[key]);
  //             }
  //         }
  //         console.log(apiFormData, 'apiFormData');
  //         const newFellow = await post_api_call('new-fellow', apiFormData);
  // if(newFellow.status === 200){
  //             setIsSuccess(true);
  //         }else{
  //             if(typeof newFellow.message !== "undefined"){
  //                 setErrorData([newFellow.message]);
  //             }
  //             setIsProcess(false);
  //         }
  //     }
      }catch(e){
          console.log(e);
          // setErrorData(['Something Went Wrong, Please Try After Some Time']);
          // setIsProcess(false);
      }
    }

    return (
        <>
        <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body table-responsive">
                    {/* <h4 className="card-title">Basic Table</h4> */}
                    {/* <p className="card-description"> Add class <code>.table</code></p> */}
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Fellow Id</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Country</th>
                          <th>Created Date<br/>(DD/MM/YYYY)</th>
                          <th>Is Approved?</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading
                        ? 
                        <tr className="text-center"><td colSpan={8}><div className="p-4"><img alt="Loader" src={require('../../assets/images/Image-Loader.gif')} /></div></td></tr>
                        :
                        <>
                        {fellowData.map((data, data_index) => (
                          <tr key={`fellow_${data_index}`}>
                            <td>{data.id}</td>
                            <td>{data.first_name}</td>
                            <td>{data.last_name}</td>
                            <td>{data.country}</td>
                            <td>{moment(data.created_at).format("DD/MM/yyyy")}</td>
                            <td><label className={`badge ${data.is_approved === 1 ? 'badge-success' : 'badge-danger'}`}>{data.is_approved === 1 ? 'Yes' : 'No'}</label></td>
                            <td><label className={`badge ${data.status === 'Active' ? 'badge-success' : 'badge-danger'}`}>{data.status}</label></td>
                            <td>
                              <button onClick={(e) => loadViewModal(e, 'view', data.id)} className="btn btn-sm btn-twitter" title="View Data"><i className="mdi mdi-eye"></i></button>
                              <button onClick={(e) => approveToggle(e, 'approve', data.is_approved, data.id)} className={`btn btn-sm ms-2 ${data.is_approved === 1 ? 'btn-danger' : 'btn-success'}`} title="Approve Fellow"><i className="mdi mdi-check-outline"></i></button>
                              {/* <button onClick={(e) => {loadViewModal(e, 'edit', data.id); fetchCountryList(); fetchPassionList(); fetchProgramList(0);}} className={`btn btn-sm ms-2 btn-inverse-info`} title="Edit Fellow"><i className="mdi mdi-pencil"></i></button>*/}
                            </td>
                          </tr>
                        ))}
                        </>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
        </div>
        {/* View Fellow Details */}
        <ViewFellowModal
            data={fellowDetails}
            show={openModal.view}
            onHide={() => handleClose('view')}
        />
        {/* Edit Fellow Details */}
        <EditFellowModal
            data={formEditData}
            show={openModal.edit}
            onHide={() => handleClose('edit')}
            countryData={countryData}
            programData={programData}
            passionData={passionData}
            updateInput={updateInput}
            updateFellowData={updateFellowData}
        />
        </>
    )    
}

export default FellowList;