import React from "react";
import {SetMetaData} from './../../helper/page-title';

function DashboardMain(){
    SetMetaData("Dashboard", "", "");
   
    return (
        <>
       
        </>
    )    
}

export default DashboardMain;