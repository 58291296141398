// Filter
const commonFilter = [
    {colMd: "3", label: "User Id", type: "number", name: "id"},
];
const filterData = [
    {colMd: "3", label: "Class", type: "multi-select", name: "class_id"},
    {colMd: "3", label: "Name", type: "text", name: "name"},
    {colMd: "3", label: "Email", type: "text", name: "email"},
    {colMd: "3", label: "Mobile No.", type: "number", name: "mobile_number"},
    // {colMd: "3", label: "Country", type: "multi-select", name: "country_id"},
    // {colMd: "3", label: "State", type: "multi-select", name: "state_id"},
    // {colMd: "3", label: "City", type: "multi-select", name: "city_id"},
    {colMd: "3", label: "Competition Applied", type: "multi-select", name: "competition_id"},
    {colMd: "2", label: "PORT Completion %", type: "select", name: "completion_id"},
    {colMd: "2", label: "Member Type", type: "select", name: "member_type"},
    // {colMd: "3", label: "Payment Validity Days", type: "select", name: "validity_id"},
    // {colMd: "3", label: "PORT Pending Section", type: "multi-select", name: "port_pending_id"},
    // {colMd: "3", label: "PORT Filled Section", type: "multi-select", name: "port_filled_id"},
    {colMd: "2", label: "Status", type: "select", name: "active_status_id"},
    // {colMd: "3", label: "Registered Date", type: "date", name: "created_at", date_type: "past"}
];

const studentFilter = [
    ...commonFilter,
    {colMd: "3", label: "School", type: "multi-select", name: "school_id"},
    {colMd: "3", label: "Class", type: "multi-select", name: "class_id"},
    {colMd: "3", label: "Name", type: "text", name: "name"},
    {colMd: "3", label: "Email", type: "text", name: "email"},
    {colMd: "3", label: "Mobile No.", type: "number", name: "mobile_number"},
    {colMd: "3", label: "Country", type: "multi-select", name: "country_id"},
    {colMd: "3", label: "State", type: "multi-select", name: "state_id"},
    {colMd: "3", label: "City", type: "multi-select", name: "city_id"},
    {colMd: "3", label: "PORT Completion %", type: "select", name: "completion_id"},
    {colMd: "3", label: "PORT Expiry Date", type: "select", name: "validity_id"},
    {colMd: "3", label: "Competition Applied", type: "select", name: "competition_id"},
    {colMd: "3", label: "PORT Pending Section", type: "multi-select", name: "port_pending_id", sub_select: true, sub_select_key: "port_pending_oper"},
    {colMd: "3", label: "PORT Filled Section", type: "multi-select", name: "port_filled_id", sub_select: true, sub_select_key: "port_filled_oper"},
    {colMd: "2", label: "Member Type", type: "select", name: "member_type"},
    {colMd: "2", label: "Status", type: "select", name: "active_status_id"},
    {colMd: "2", label: "Registered Date", type: "date", name: "created_at", date_type: "past"}
];

const studentFilterPrin = [
    ...commonFilter,
    ...filterData
];

const studentFilterTeach = [
    ...commonFilter,
    ...filterData
];
//Filter
const filterInitial= {
    // id: "",
    // school_id: "",
    // class_id: "",
    // name: "",
    // email: "",
    // mobile_number: "",
    // country_id: "",
    // state_id: "",
    // city_id: "",
    // member_type: "",
    // completion_id: "",
    // validity_id: "",
    // competition_id: "",
    // port_pending_id: "",
    // port_filled_id: "",
    // active_status_id: "",
    // created_at: "",
    mobile_code_id: {id: 101, phone_code: "91"},
    port_pending_oper: {id: 'OR', oper: "OR"},
    port_filled_oper: {id: 'OR', oper: "OR"}
}
// const filterInitialTeach= {
//     id: "",
//     class_id: "",
//     name: "",
//     email: "",
//     mobile_number: "",
//     member_type: "",
//     completion_id: "",
//     competition_id: "",
//     active_status_id: "",
//     mobile_code_id: {id: 101, phone_code: "91"}
// }

const data = {
    studentFilter,
    studentFilterPrin,
    studentFilterTeach,
    filterInitial,
    // filterInitialTeach
};

export default data;