import React from 'react';
// import Col from 'react-bootstrap/Col';
// import Form from 'react-bootstrap/Form';
import Select  from 'react-select';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import {
  Col,
  Form,
  InputGroup
} from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { RSstandardStyle } from '../../config/react-select';
// import { FormUpload } from '../../form/upload';
import { currentMonthYear, todayDateForm } from '../form/date';
import './../../assets/css/pages/modal.css';
// import { LoaderMedium } from '../components/loader';

function FilterFloatMaster(props) {
    // console.log(props.data, 'props.data')
    // const [expandedItem, setExpandedItem] = useState("0")
    // useEffect(() => {
    //     setTimeout(() => {
    //         setExpandedItem("1");
    //         console.log('llll')
    //     }, 3000);
    //     }, []);
    return (
      <> 
            {/* <div>
                {props.loader 
                ?
                    <div className="text-center mt-5 mb-5">
                        <LoaderMedium size={45} />
                    </div>
                :
                    <> */}
                    <Accordion
                        className="filter-custom filter-float"
                        // defaultActiveKey={expandedItem}
                        // onSelect={(e) => {
                        //     console.log(e)
                        //     if (e !== null){
                        //         setExpandedItem(e);
                        //     }
                        // }}
                    >
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                Filter & Download
                            </Accordion.Header>
                            <Accordion.Body className="p-2">
                                {props._formData.map((form, form_index) => (
                                    <Form key={`form_${form_index}`} className="filter-form">
                                        <div>
                                        <fieldset>
                                            {/* <legend>{form.label}</legend> */}
                                            <div className="row m-0">
                                            {form.fields.map((field, field_index) => (
                                                <Col xs={12} md={field.colMd} key={`field_${field_index}`} className="p-1">
                                                    <Form.Group className="mb-0">
                                                        <Form.Label
                                                            // htmlFor={field.name}
                                                        >
                                                            {field.label}
                                                            {field.tagline && <span className="fw-light">:&nbsp;{field.tagline}</span>}
                                                        </Form.Label>
                                                        {/* Input */}
                                                        {(field.type === "number" 
                                                            || field.type === "text" 
                                                            || field.type === "email"
                                                        ) 
                                                        ?
                                                            <>
                                                            <div className="d-flex">
                                                            {field.name === "mobile_number" && (
                                                                <div className="w-200p">
                                                                <Select
                                                                    id={'mobile_code_id'}
                                                                    options={props.selectOptions['mobile_code_id']?.data} 
                                                                    // defaultValue={props.data['mobile_code_id']}
                                                                    defaultValue={{id: 101, phone_code: "91"}}
                                                                    value={props.data['mobile_code_id']||""}
                                                                    placeholder={field.placeholderData && field.placeholderData.length > 0 ? `${field.placeholderData[0]}`: ''}
                                                                    onChange={(value) => props._updateInput(props.formType, 'object', 'select-react', 'mobile_code_id', value)}
                                                                    getOptionLabel ={(option) => `+${option[props.selectOptions['mobile_code_id']?.label]}`}
                                                                    getOptionValue ={(option) => option[props.selectOptions['mobile_code_id']?.value]}
                                                                    menuPosition={'fixed'}
                                                                    styles={RSstandardStyle}
                                                                />
                                                            </div>
                                                            )}
                                                            <input
                                                                className="form-control"
                                                                id={field.name}
                                                                name={field.name}
                                                                // type="text"
                                                                // type={field.type === "number" ? "text" : field.type} 
                                                                type={field.type}
                                                                value={props.data[field.name] || ''}
                                                                onChange={(e) => props._updateInput(props.formType, 'object', 'input', field.name, e.target.value)}
                                                                // placeholder={typeof field.placeholderData !== "undefined" && field.placeholderData.length > 0  ? `Eg.${field.placeholderData.length === 1 ? '' : ' 1'}: ${field.placeholderData[0]}` : field.placeholder}
                                                                readOnly={props.isProcess}
                                                                min={field.type === "number" ? 0 : ''} 
                                                            />
                                                            </div>
                                                            </>
                                                        :
                                                            // Textarea
                                                            field.type === "textarea"
                                                            ?
                                                                <>
                                                                <textarea
                                                                    className="form-control"
                                                                    id={field.name}
                                                                    name={field.name}
                                                                    rows={field.rows}
                                                                    value={props.data[field.name] || ''}
                                                                    onChange={(e) => props._updateInput(props.formType, 'object', 'textarea', field.name, e.target.value)}
                                                                    // placeholder={typeof field.placeholderData !== "undefined" && field.placeholderData.length > 0  ? `Eg.${field.placeholderData.length === 1 ? '' : ' 1'}: ${field.placeholderData[0]}` : field.placeholder}
                                                                    placeholder={typeof field.placeholderData !== "undefined" && field.placeholderData.length > 0  ?  field.placeholderData[0] : ""}
                                                                    readOnly={props.isProcess}
                                                                />
                                                                </>
                                                            :   
                                                            
                                                        // Select-Single-Start/End Year
                                                        field.type === "year"
                                                        ? 
                                                            // <div className="d-flex">
                                                                <div className="w-140p">
                                                                    <Select
                                                                        isClearable={true}
                                                                        id={field.name}
                                                                        options={props.yearData} 
                                                                        defaultValue={props.data[field.name]}
                                                                        value={props.data[field.name] || ""}
                                                                        // label={"Select Year"}
                                                                        placeholder={field.placeholderData && field.placeholderData.length > 0 ? `Eg. ${field.placeholderData[0]}`: ''}
                                                                        onChange={(value) => props._updateInput(props.formType, 'object', 'select-react', field.name, value)}
                                                                        getOptionLabel ={(option) => option.year}
                                                                        getOptionValue ={(option) => option.id}
                                                                        menuPlacement="auto"
                                                                        menuPosition={'fixed'}
                                                                        styles={RSstandardStyle}
                                                                    />
                                                                </div>
                                                            :
                                                                    field.type === "month"
                                                                        ?
                                                                        <div>
                                                                            <input type="month" className="form-control w-150p p-1" name={field.name} value={props.data[field.name] || ""} onChange={(e) => props._updateInput(props.formType, 'object','input', field.name, e.target.value)} max={currentMonthYear()} min="1922-01"/>
                                                                            
                                                                        </div>
                                                                        :
                                                                            field.type === "multi-select"
                                                                            ?
                                                                            <div className="d-flex">
                                                                            {(typeof field.sub_select !== "undefined" && field.sub_select) && (
                                                                                <div className="sub-select">
                                                                                <Select
                                                                                    options={props.selectOptions[field.sub_select_key]?.data} 
                                                                                    defaultValue={props.data[field.sub_select_key]}
                                                                                    // defaultValue={{id: 101, phone_code: "91"}}
                                                                                    value={props.data[field.sub_select_key]||""}
                                                                                    // placeholder={field.placeholderData && field.placeholderData.length > 0 ? `${field.placeholderData[0]}`: ''}
                                                                                    placeholder=""
                                                                                    onChange={(value) => props._updateInput(props.formType, 'object', 'select-react', field.sub_select_key, value)}
                                                                                    getOptionLabel ={(option) => option[props.selectOptions[field.sub_select_key]?.label]}
                                                                                    getOptionValue ={(option) => option[props.selectOptions[field.sub_select_key]?.value]}
                                                                                    menuPosition={'fixed'}
                                                                                    styles={RSstandardStyle}
                                                                                />
                                                                            </div>
                                                                            )}
                                                                            <div className={`${(typeof field.sub_select !== "undefined" && field.sub_select) ? 'sub-parent' : 'w-100'}`}>
                                                                                <Select
                                                                                    closeMenuOnSelect={false}
                                                                                    id={field.name}
                                                                                    options={props.selectOptions[field.name]?.data} 
                                                                                    defaultValue={props.data[field.name]}
                                                                                    value={props.data[field.name] || ""}
                                                                                    placeholder={field.placeholderData && field.placeholderData.length > 0 ? `${field.placeholderData[0]}`: ''}
                                                                                    // placeholder={field.placeholder ? field.placeholder: ''}
                                                                                    onChange={(value) => props._updateInput(props.formType, 'object', 'select-multi', field.name, value)}
                                                                                    getOptionLabel ={(option) => option[props.selectOptions[field.name]?.label]}
                                                                                    getOptionValue ={(option) => option[props.selectOptions[field.name]?.value]}
                                                                                    // menuPlacement="auto"
                                                                                    menuPosition={'fixed'}
                                                                                    styles={RSstandardStyle}
                                                                                    classNamePrefix="reactCSel"
                                                                                    // isOptionDisabled={() => (props.data[field.name] && props.data[field.name].length) >= props.selectOptions[field.name].max}
                                                                                    isSearchable={true}
                                                                                    // filterOption={(option, searchtext) => customFilter(option, searchtext, props.selectOptions[field.name].format)}
                                                                                    // components={{ValueContainer: (e) => selectComponent(e, props.selectOptions[field.name].format.key)}}
                                                                                    // selectionLimit={2}
                                                                                    isMulti
                                                                                />
                                                                            </div>
                                                                            </div>
                                                                            :
                                                                                field.type === "select"
                                                                                ?
                                                                                <div className="w-100">
                                                                                    <Select
                                                                                        // closeMenuOnSelect={false}
                                                                                        isClearable={true}
                                                                                        id={field.name}
                                                                                        options={props.selectOptions[field.name]?.data} 
                                                                                        defaultValue={props.data[field.name]}
                                                                                        value={props.data[field.name] || ""}
                                                                                        // placeholder={field.placeholderData && field.placeholderData.length > 1 ? `Eg. 1: ${field.placeholderData[0]}`: field.placeholderData.length > 0
                                                                                        // ? `${field.placeholderData[0]}` : ''}
                                                                                        placeholder={field.placeholderData && field.placeholderData.length > 0 ? `${field.placeholderData[0]}`: ''}
                                                                                        onChange={(value) => props._updateInput(props.formType, 'object', 'select-react', field.name, value)}
                                                                                        getOptionLabel ={(option) => option[props.selectOptions[field.name]?.label]}
                                                                                        getOptionValue ={(option) => option[props.selectOptions[field.name]?.value]}
                                                                                        // menuPlacement="auto"
                                                                                        menuPosition={'fixed'}
                                                                                        styles={RSstandardStyle}
                                                                                        // isDisabled ={true}
                                                                                        // formatOptionLabel={(e) => formatOptionLabel(e, props.selectOptions[field.name].format)}
                                                                                        // classNamePrefix="reactCSel"
                                                                                        // isOptionDisabled={() => (props.data[field.name] && props.data[field.name].length) >= props.selectOptions[field.name].max}
                                                                                        // isSearchable={true}
                                                                                        // filterOption={(option, searchtext) => customFilter(option, searchtext, props.selectOptions[field.name].format)}
                                                                                        // components={{ValueContainer: (e) => selectComponent(e, props.selectOptions[field.name].format.key)}}
                                                                                        // isOptionDisabled={() => props.data[field.name]?.length > props.selectOptions[field.name]?.max}
                                                                                        // selectionLimit={2}
                                                                                        // isMulti
                                                                                    />
                                                                                </div>
                                                                                :
                                                                                    field.type === "date"
                                                                                    ?
                                                                                    <input
                                                                                        className="form-control"
                                                                                        id={field.name}
                                                                                        name={field.name}
                                                                                        // type="text"
                                                                                        type={"date"}
                                                                                        min={field.date_type && field.date_type === "future" ?  `${todayDateForm()}` : '1920-01-01'}
                                                                                        max={field.date_type && field.date_type === "past" ?  `${todayDateForm()}` : '2124-12-31'}
                                                                                        value={props.data[field.name] || ""}
                                                                                        onChange={(e) => props._updateInput(props.formType, 'object', 'input', field.name, e.target.value)}
                                                                                        placeholder={typeof field.placeholderData !== "undefined" && field.placeholderData.length > 0  ? `Eg.${field.placeholderData.length === 1 ? '' : ' 1'}: ${field.placeholderData[0]}` : field.placeholder}
                                                                                        readOnly={props.isProcess}
                                                                                    />
                                                                                    :
                                                                                    <></>
                                                        }
                                                        {(field.placeholderData && field.placeholderData.length > 1)
                                                            ?
                                                            <div className="fs-12p fst-italic mt-1 text-black-50">
                                                                {field.placeholderData.map((p_data, p_index) => (
                                                                    <span key={`place_hold${p_index}`}>
                                                                    {p_index !== 0 && (
                                                                        <span>
                                                                            <b>Eg. {p_index+1}</b>: {p_data} {p_index+1 !== field.placeholderData.length && (', ')}
                                                                        </span>
                                                                    )}
                                                                    </span>
                                                                ))}
                                                            </div>
                                                            : (field.placeholderData && field.placeholderData.length === 1
                                                                && (field.type === "year_to_from" 
                                                                    || field.type === "month_year_from"
                                                                    || field.type === "month"))
                                                                ?
                                                                <div className="fs-12p fst-italic mt-1 text-black-50">
                                                                    <span><b>Eg.</b>: {field.placeholderData[0]}</span>
                                                                </div>
                                                                : <></>
                                                        }
                                                    </Form.Group>
                                                </Col>
                                            ))}
                                            </div>
                                        </fieldset>
                                        </div>
                                        <p className="text-end mb-0">
                                            <button className="btn-inverse-light btn-link reset-btn" onClick={(e) => props._resetFilterForm(e)}>Reset Filter</button>
                                        </p>
                                        <div className="text-center mb-2 mt-3 justify-content-center filter-excel">
                                            <div className="d-inline-flex">
                                            <div>
                                            {!props._isProcess[props.formType]
                                            ?
                                                // props._isBtnDisabled[props.formType]
                                                // ?
                                                // :
                                                    <button className="btn btn-primary bg-color-blue btn-sm" type="submit" onClick={(e) => props._submitForm(e, props.formType)}><span className="fs-6">Search</span></button>
                                            :
                                                <button className="btn btn-primary bg-color-blue btn-sm d-flex" disabled type="submit"><img className="loader-filter" src={require('../../assets/images/Image-Loader.gif')} alt="Loader" /> &nbsp;<span className="fs-6">Searching...</span></button>
                                            }
                                            </div>
                                            {props._is_download && (
                                                <>
                                                <div>
                                                {!props._isProcess.download
                                                ?
                                                    <button className="btn btn-primary bg-color-blue btn-sm ms-2 " type="submit" onClick={(e) => props._dataExport(e, 'download')}><p className="fs-6 mb-0">Export to Excel</p></button>
                                                :
                                                    <button className="btn btn-primary bg-color-blue btn-sm ms-2 d-flex" disabled type="submit"><img className="loader-filter" src={require('../../assets/images/Image-Loader.gif')} alt="Loader" /> &nbsp;<p className="fs-6 mb-0">Exporting...</p></button>
                                                }
                                                </div>
                                                <InputGroup className="ms-2">
                                                    {/* <InputGroup.Text><i className="fa fa-envelope"></i>&nbsp;<em>*</em></InputGroup.Text> */}
                                                    <FloatingLabel
                                                        controlId="floatingDown"
                                                        label="Excel Sheet Name"
                                                        className="w-200p"
                                                    >
                                                        <Form.Control 
                                                            type="text" 
                                                            placeholder="Excel Sheet Name" 
                                                            autoComplete="user_name" 
                                                            value={props._excelSheetName}
                                                            onChange={(e) => props._updateInput('download', 'object', 'input', '', e.target.value)}
                                                        />
                                                    </FloatingLabel>
                                                </InputGroup>
                                                </>
                                            )}
                                            </div>
                                        </div>
                                    </Form>
                                ))}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    {/* </>
                }
            </div> */}
      </>
    );
}
  
export default FilterFloatMaster;