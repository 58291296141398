import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import {CKEditor} from '@ckeditor/ckeditor5-react';
// import {Link} from '@ckeditor/ckeditor5-link';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import Accordion from 'react-bootstrap/Accordion';
import Select  from 'react-select';
import { RSstandardStyle } from '../config/react-select';
// import { FormUpload } from '../../form/upload';
import { currentMonthYear, todayDateForm } from '../components/form/date';
import { CharacterCount } from "../components/form/count";
import './../assets/css/pages/modal.css';
import { error_api_call } from '../actions/action-creators/common';
import { alertResponseMessage } from '../actions/action-creators/response';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { LoaderMedium } from '../components/loader';

function ModalMaster(props) {
    // const [previewPicImg, setPreviewPicImg] = useState(null);
    const [croppedImg, setCroppedImg] = useState(null);
    const cropperRef = React.createRef(null);
    const [previewDocData, setPreviewDoc] = useState([]);
    const onCrop = (field_name) => {
        const cropper = cropperRef.current?.cropper;
        // setCroppedImg(cropper.getCroppedCanvas().toDataURL());
        // cropper.getCroppedCanvas({
        //     fillColor: '#fff',
        //   }).toDataURL('image/jpeg');
        setCroppedImg(cropper.getCroppedCanvas({
            fillColor: '#fff',
          }).toDataURL('image/jpeg'));
        // props._updateInput(props.formType, 'object', 'img-crop', field_name+'_crop', cropper.getCroppedCanvas().toDataURL('image/jpeg'));
        cropper.getCroppedCanvas({
            fillColor: '#fff',
          }).toBlob((blob) => {
            console.log(blob, 'blobbb');
            // setPreviewPicBlob(blob);
            console.log('bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb',  field_name)
            props._updateInput(props.formType, 'object', 'input-file', field_name, blob);
        }, 'image/jpeg');
    };
    const previewDoc = (event, form_type, file_type, field_name='') => {
        try{
            const files = event.target.files;
            if(files.length > 0){
                const uploadedImg = [];
                const documetAllowedExt = file_type === 'image' ?  ["png", "jpg", "jpeg"] : ["png", "jpg", "jpeg", "pdf"];
                const imageExt = ["image/png", "image/jpg", "image/jpeg"];
                const allowedSize = process.env.REACT_APP_UPLOAD_FILE_SIZE;//2MB-200000
                const allowedQty = form_type === 'Pic' ? 1 : process.env.REACT_APP_UPLOAD_FILE_QTY - previewDocData.length;
                if(allowedQty){
                    for(let i=0; i<files.length; i++){
                        const imgFile = files[i];
                        var ext = imgFile.name.slice((imgFile.name.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
                        //Check for file size and extension
                        // const size = Math.round((imgFile.size / 1024));
                        // console.log(size, 'size')
                        if(imgFile.size <= allowedSize && documetAllowedExt.includes(ext)){
                            const imgData = {data: imgFile,
                                            src: URL.createObjectURL(imgFile),
                                            title: imgFile.name,
                                            is_image: imageExt.includes(imgFile.type.toLowerCase()) ? true : false,
                                            ext
                                        };
                            uploadedImg.push(imgData);
                        }else{
                            alertResponseMessage({alert_type: 2,
                                                message: `${file_type === 'image'
                                                            ? `Photo/Image allowed size is ${process.env.REACT_APP_FILE_SIZE} and it must be an image.`
                                                            : `Image/Doc allowed size is ${process.env.REACT_APP_FILE_SIZE} and it must be an image/pdf.`
                                                }`});
                        }
                        // if(form_type !== 'Pic' && uploadedImg.length >= 2){
                        if(uploadedImg.length >= allowedQty){
                            break;
                        }
                    }
                }
                console.log(uploadedImg, 'uploadedImg')
                if(form_type === 'Pic'){
                    if(uploadedImg.length > 0){
                        // props._updateInput(props.formType, 'object', 'input-file', 'show_image', true);
                        // setPreviewPicImg({...uploadedImg[0]});
                        // setBtnDisabled({...isBtnDisabled, Pic: false});
                        props._updateInput(props.formType, 'object', 'input-preview', field_name, {...uploadedImg[0]});
                    }else{
                        // setPreviewPicImg(null);
                        // setBtnDisabled({...isBtnDisabled, Pic: true});
                        props._updateInput(props.formType, 'object', 'input-preview', field_name, null);
                    }
                }else{
                    const attemptQty = previewDocData.length + files.length;
                    if(attemptQty > process.env.REACT_APP_UPLOAD_FILE_QTY){
                        alertResponseMessage({alert_type: 2,
                                            message: `Maximum 2 Images${file_type === 'image' ? '' : '/pdf'} Allowed`});
                    }
                    const newImgData = previewDocData.concat(uploadedImg);
                    setPreviewDoc([...newImgData]);
                    // if(newImgData.length > 0){
                    //     setBtnDisabled({...isBtnDisabled, [form_type]: false});
                    // }
                }
            }
        }catch(e){
            console.log(e)
            form_type === 'Pic'
                ? 
                    // setPreviewPicImg(null)
                    props._updateInput(props.formType, 'object', 'input-preview', field_name, null)
                : setPreviewDoc([]);
            error_api_call({error: `portfolio|edit|previewDoc|${e}`});
        }
    }
    // useEffect(() => {
    //     console.log('hhh');
    //     setPreviewPicImg(null);
    // }, []);
    // console.log(props, 'propspropspropspropspropspropspropspropsprops')
    // const formatOptionLabel = (e, payload) => {
    //     if(payload.req === true){
    //         const content = `<b>${e[payload.key]}</b><span class="hideSpan">: ${e[payload.subkey]}</span>`;
    //         return (
    //             <span dangerouslySetInnerHTML={{ __html: content }} />
    //         )
    //     }
    // };
    //Filter Option
    // const customFilter = (option, searchText, payload) => {
    //     if (
    //         option.data[payload.key].toLowerCase().includes(searchText.toLowerCase()) ||
    //         option.data[payload.subkey].toLowerCase().includes(searchText.toLowerCase())
    //     ) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }

    return (
      <> 
        <Modal dialogClassName="modal-70w" show={props.isShow||false} onHide={(e) => props._toggleModal(e, false)} size="lg" className="mod-custom team-detail" backdrop="static">
            {props._isProcess[props._formData[0]?.form_type]
            ?
                <Modal.Header>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
            :
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
            }
            <Modal.Body>
                {props.loader 
                ?
                    <div className="text-center mt-5 mb-5">
                        <LoaderMedium size={45} />
                    </div>
                :
                    <>
                    {props._formData.map((form, form_index) => (
                        <Form key={`form_${form_index}`}>
                            <div className="scroll-div">
                            <fieldset>
                                <legend>{form.label}</legend>
                                <div className="row m-0">
                                {form.fields.map((field, field_index) => (
                                    <Col xs={12} md={field.colMd} key={`field_${field_index}`}>
                                        <Form.Group className="mb-4">
                                            <Form.Label
                                                // htmlFor={field.name}
                                            >
                                                {field.label}
                                                {field.required && (<em className="required">*</em>)}
                                                {field.tagline && <span className="fw-light">:&nbsp;{field.tagline}</span>}
                                            </Form.Label>
                                            {/* Input */}
                                            {(field.type === "number" 
                                                || field.type === "text" 
                                                || field.type === "email"
                                            ) 
                                            ?
                                                <>
                                                <input
                                                    className="form-control"
                                                    id={field.name}
                                                    name={field.name}
                                                    // type="text"
                                                    // type={field.type === "number" ? "text" : field.type} 
                                                    type={field.type}
                                                    value={props.data[field.name]}
                                                    onChange={(e) => props._updateInput(props.formType, 'object', 'input', field.name, e.target.value)}
                                                    placeholder={typeof field.placeholderData !== "undefined" && field.placeholderData.length > 0  ? `Eg.${field.placeholderData.length === 1 ? '' : ' 1'}: ${field.placeholderData[0]}` : field.placeholder}
                                                    readOnly={props.isProcess}
                                                    min={field.type === "number" ? 0 : ''} 
                                                />
                                                {field.count && (
                                                    <CharacterCount
                                                        max={field.count}
                                                        string={props.data[field.name]}
                                                    />
                                                )}
                                                </>
                                            :
                                                // Textarea
                                                field.type === "textarea"
                                                ?
                                                    <>
                                                    <textarea
                                                        className="form-control"
                                                        id={field.name}
                                                        name={field.name}
                                                        rows={field.rows}
                                                        value={props.data[field.name]}
                                                        onChange={(e) => props._updateInput(props.formType, 'object', 'textarea', field.name, e.target.value)}
                                                        // placeholder={typeof field.placeholderData !== "undefined" && field.placeholderData.length > 0  ? `Eg.${field.placeholderData.length === 1 ? '' : ' 1'}: ${field.placeholderData[0]}` : field.placeholder}
                                                        placeholder={typeof field.placeholderData !== "undefined" && field.placeholderData.length > 0  ?  field.placeholderData[0] : ""}
                                                        readOnly={props.isProcess}
                                                    />
                                                    {field.count && (
                                                        <CharacterCount
                                                            max={field.count}
                                                            string={props.data[field.name]}
                                                        />
                                                    )}
                                                    </>
                                                :   
                                                field.type === "ckeditor"
                                                ?
                                                    <div>
                                                        <CKEditor
                                                            editor={ ClassicEditor }
                                                            data={props.data[field.name]}
                                                            // data=''
                                                            // onInit={ editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log( 'Editor is ready to use!', editor );
                                                            // } }
                                                            // onInit={ editor => {
                                                            //     editor.ui.view.editable.element.style.minHeight = "100px";
                                                            // }}
                                                            // onReady={(editor) => {
                                                            //     editor.ui.view.editable.element.style.minHeight = "100px";
                                                            // }}
                                                            onChange={ ( event, editor ) => {
                                                                // const data = editor.getData();
                                                                // console.log( { event, editor, data } );
                                                                props._updateInput(props.formType, 'object', 'textarea', field.name, editor.getData())
                                                            }}
                                                            onReady={(editor) => {
                                                                // You can store the "editor" and use when it is needed.
                                                                // console.log("Editor is ready to use!", editor);
                                                                editor.editing.view.change((writer) => {
                                                                    writer.setStyle(
                                                                        "height",
                                                                        `${field.height ? field.height : '200'}px`,
                                                                        editor.editing.view.document.getRoot()
                                                                    );
                                                                });
                                                            }}
                                                            config={ {
                                                                toolbar: [ 'bold', '|', 'italic', '|', 'bulletedList', '|', 'numberedList'],
                                                                // plugins: [ Link, AutoLink],
                                                                // toolbar: [ 'bold', '|', 'italic', '|', 'bulletedList', '|', 'numberedList', '|', 'link' ],
                                                                // toolbar: [ 'bold', '|', 'italic' ]
                                                            }}
                                                        />
                                                        {field.count && (
                                                            <CharacterCount
                                                                max={field.count}
                                                                string={props.data[field.name]}
                                                            />
                                                        )}
                                                    </div>
                                                :
                                                // Image
                                                field.type === "image-upload"
                                                ?
                                                    <>
                                                    <div className="mt-3">
                                                    {props.data[field.name+'_preview'] &&
                                                        <>
                                                        <div className="d-flex">
                                                        <Cropper
                                                            src={props.data[field.name+'_preview'].src}
                                                            style={{ height: 200, width: 200 }}
                                                            initialAspectRatio={1 / 1}
                                                            aspectRatio={1/1}
                                                            guides={false}
                                                            crop={() => onCrop(field.name)}
                                                            ref={cropperRef}
                                                            dragMode="move"
                                                            // cropBoxResizable={false}
                                                            background={false}
                                                            // minContainerWidth={"100%"}
                                                            minContainerWidth={200}
                                                            zoomable={false}
                                                            highlight={false}
                                                            checkOrientation={false}
                                                            // getCroppedCanvas={{fillcolor: "#FFFFFF"}}
                                                        />
                                                        <div className="d-none d-sm-flex ms-4">
                                                            <img src={croppedImg} className="img-thumbnail cropper-preview-img" alt={"Opp. Logo"} />
                                                        </div>
                                                        </div>
                                                        </>
                                                    }
                                                    <div className="upload-img text-center mt-3">
                                                        <div className="m-3">
                                                            {!props.data[field.name+'_preview'] && props.data[field.name] &&
                                                                <img src={props.data[field.name]} alt={"profile_log"} className="img-thumbnail cropper-preview-img" />
                                                            }
                                                        </div>
                                                        <div className="btn btn-light btn-outline-behance btn-sm">
                                                            <span>Upload photo/logo</span>
                                                            <input type="file" className="logo-input" name="profile_log" onChange={(e) => previewDoc(e,'Pic', 'image', field.name+'_preview')} accept="image/x-png,image/jpg,image/jpeg" />
                                                        </div>
                                                    </div>
                                                    </div>
                                                    </>
                                                :
                                            // Select-Single-Start/End Year
                                            field.type === "year"
                                            ? 
                                                // <div className="d-flex">
                                                    <div className="w-140p">
                                                        <Select
                                                            id={field.name}
                                                            options={props.yearData} 
                                                            defaultValue={props.data[field.name]}
                                                            value={props.data[field.name]}
                                                            // label={"Select Year"}
                                                            placeholder={field.placeholderData && field.placeholderData.length > 0 ? `Eg. ${field.placeholderData[0]}`: ''}
                                                            onChange={(value) => props._updateInput(props.formType, 'object', 'select-react', field.name, value)}
                                                            getOptionLabel ={(option) => option.year}
                                                            getOptionValue ={(option) => option.id}
                                                            menuPlacement="auto"
                                                            menuPosition={'fixed'}
                                                            styles={RSstandardStyle}
                                                        />
                                                    </div>
                                                :
                                                    field.type === "year_present"
                                                    ?
                                                    <div>
                                                        {!props.data.is_present && (
                                                            <div className="w-140p">
                                                                <Select
                                                                    id={field.name}
                                                                    options={props.yearData} 
                                                                    defaultValue={props.data[field.name]}
                                                                    value={props.data[field.name]}
                                                                    // label={"Select Year"}
                                                                    placeholder={field.placeholderData && field.placeholderData.length > 0 ? `Eg. ${field.placeholderData[0]}`: ''}
                                                                    onChange={(value) => props._updateInput(props.formType, 'object', 'select-react', field.name, value)}
                                                                    getOptionLabel ={(option) => option.year}
                                                                    getOptionValue ={(option) => option.id}
                                                                    menuPlacement="auto"
                                                                    menuPosition={'fixed'}
                                                                    styles={RSstandardStyle}
                                                                />
                                                            </div>
                                                        )}
                                                        <input className="form-check-input" type="checkbox" id={`${field.name}_present`} value={props.data.is_present} checked={props.data.is_present} onChange={(e) => props._updateInput(props.formType, 'object', 'checkbox', 'is_present', e.target.checked)} />
                                                        <label className="form-check-label" htmlFor={`${field.name}_present`}>&nbsp;Present</label>
                                                    </div>
                                                    : field.type === "month_present"
                                                        ?
                                                            <div>
                                                                {!props.data.is_present && (
                                                                    <>
                                                                    <input type="month" className="form-control w-150p p-1" name={field.name} value={props.data[field.name]} onChange={(e) => props._updateInput(props.formType, 'object','input', field.name, e.target.value)} max={currentMonthYear()} />
                                                                    </>
                                                                )}
                                                                <input className="form-check-input" type="checkbox" value="" id={`${field.name}_present`} checked={props.data.is_present} onChange={(e) => props._updateInput(props.formType, 'object','checkbox', 'is_present', e.target.checked)}/>
                                                                <label className="form-check-label" htmlFor={`${field.name}_present`}>&nbsp;Present</label>
                                                            </div>
                                                        :   field.type === "month"
                                                            ?
                                                            <div>
                                                                <input type="month" className="form-control w-150p p-1" name={field.name} value={props.data[field.name]} onChange={(e) => props._updateInput(props.formType, 'object','input', field.name, e.target.value)} max={currentMonthYear()} min="1922-01"/>
                                                                
                                                            </div>
                                                            :
                                                                field.type === "multi-select"
                                                                ?
                                                                <div className="w-100">
                                                                    <Select
                                                                        closeMenuOnSelect={false}
                                                                        id={field.name}
                                                                        options={props.selectOptions[field.name]?.data} 
                                                                        defaultValue={props.data[field.name]}
                                                                        value={props.data[field.name]}
                                                                        placeholder={field.placeholderData && field.placeholderData.length > 0 ? `${field.placeholderData[0]}`: ''}
                                                                        // placeholder={field.placeholder ? field.placeholder: ''}
                                                                        onChange={(value) => props._updateInput(props.formType, 'object', 'select-multi', field.name, value)}
                                                                        getOptionLabel ={(option) => option[props.selectOptions[field.name]?.label]}
                                                                        getOptionValue ={(option) => option[props.selectOptions[field.name]?.value]}
                                                                        // menuPlacement="auto"
                                                                        menuPosition={'fixed'}
                                                                        styles={RSstandardStyle}
                                                                        classNamePrefix="reactCSel"
                                                                        // isOptionDisabled={() => (props.data[field.name] && props.data[field.name].length) >= props.selectOptions[field.name].max}
                                                                        isSearchable={true}
                                                                        // filterOption={(option, searchtext) => customFilter(option, searchtext, props.selectOptions[field.name].format)}
                                                                        // components={{ValueContainer: (e) => selectComponent(e, props.selectOptions[field.name].format.key)}}
                                                                        // selectionLimit={2}
                                                                        isMulti
                                                                    />
                                                                </div>
                                                                :
                                                                    field.type === "select"
                                                                    ?
                                                                    <div className="w-100">
                                                                        <Select
                                                                            // closeMenuOnSelect={false}
                                                                            id={field.name}
                                                                            options={props.selectOptions[field.name]?.data} 
                                                                            defaultValue={props.data[field.name]}
                                                                            value={props.data[field.name]}
                                                                            // placeholder={field.placeholderData && field.placeholderData.length > 1 ? `Eg. 1: ${field.placeholderData[0]}`: field.placeholderData.length > 0
                                                                            // ? `${field.placeholderData[0]}` : ''}
                                                                            placeholder={field.placeholderData && field.placeholderData.length > 0 ? `${field.placeholderData[0]}`: ''}
                                                                            onChange={(value) => props._updateInput(props.formType, 'object', 'select-react', field.name, value)}
                                                                            getOptionLabel ={(option) => option[props.selectOptions[field.name]?.label]}
                                                                            getOptionValue ={(option) => option[props.selectOptions[field.name]?.value]}
                                                                            // menuPlacement="auto"
                                                                            menuPosition={'fixed'}
                                                                            styles={RSstandardStyle}
                                                                            // isDisabled ={true}
                                                                            // formatOptionLabel={(e) => formatOptionLabel(e, props.selectOptions[field.name].format)}
                                                                            // classNamePrefix="reactCSel"
                                                                            // isOptionDisabled={() => (props.data[field.name] && props.data[field.name].length) >= props.selectOptions[field.name].max}
                                                                            // isSearchable={true}
                                                                            // filterOption={(option, searchtext) => customFilter(option, searchtext, props.selectOptions[field.name].format)}
                                                                            // components={{ValueContainer: (e) => selectComponent(e, props.selectOptions[field.name].format.key)}}
                                                                            // isOptionDisabled={() => props.data[field.name]?.length > props.selectOptions[field.name]?.max}
                                                                            // selectionLimit={2}
                                                                            // isMulti
                                                                        />
                                                                    </div>
                                                                    :
                                                                        field.type === "date"
                                                                        ?
                                                                        <input
                                                                            className="form-control"
                                                                            id={field.name}
                                                                            name={field.name}
                                                                            // type="text"
                                                                            type={"date"}
                                                                            min={field.date_type && field.date_type === "future" ?  `${todayDateForm()}` : '2024-11-01'}
                                                                            value={props.data[field.name]}
                                                                            onChange={(e) => props._updateInput(props.formType, 'object', 'input', field.name, e.target.value)}
                                                                            placeholder={typeof field.placeholderData !== "undefined" && field.placeholderData.length > 0  ? `Eg.${field.placeholderData.length === 1 ? '' : ' 1'}: ${field.placeholderData[0]}` : field.placeholder}
                                                                            readOnly={props.isProcess}
                                                                        />
                                                                        :
                                                                        <></>
                                            }
                                            {(field.placeholderData && field.placeholderData.length > 1)
                                                ?
                                                <div className="fs-12p fst-italic mt-1 text-black-50">
                                                    {field.placeholderData.map((p_data, p_index) => (
                                                        <span key={`place_hold${p_index}`}>
                                                        {p_index !== 0 && (
                                                            <span>
                                                                <b>Eg. {p_index+1}</b>: {p_data} {p_index+1 !== field.placeholderData.length && (', ')}
                                                            </span>
                                                        )}
                                                        </span>
                                                    ))}
                                                </div>
                                                : (field.placeholderData && field.placeholderData.length === 1
                                                    && (field.type === "year_to_from" 
                                                        || field.type === "month_year_from"
                                                        || field.type === "month"))
                                                    ?
                                                    <div className="fs-12p fst-italic mt-1 text-black-50">
                                                        <span><b>Eg.</b>: {field.placeholderData[0]}</span>
                                                    </div>
                                                    : <></>
                                            }
                                        </Form.Group>
                                    </Col>
                                ))}
                                </div>
                            </fieldset>
                            </div>
                            {/* Error */}
                            <div className="col-md-12 col-12 col-sm-12">
                            {(props._errorData[form.form_type] && props._errorData[form.form_type].length > 0) && (
                                <div className="alert alert-danger mt-4" role="alert">
                                    {/* <p className="mb-0">{props._errorData[form.form_type][0]}</p> */}
                                    <p>Please enter/modify following Data:</p>
                                    <ol>
                                    {props._errorData[form.form_type].map((error, error_index) => (
                                        <li key={`error_${error_index}`}>{error}</li>
                                    ))}
                                    </ol>
                                </div>
                            )}
                            </div>
                            {/* Process */}
                            <p className="text-center mb-2 mt-3">
                            {!props._isProcess[form.form_type]
                            ?
                                // props._isBtnDisabled[form.form_type]
                                // ?
                                //     <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                // :
                                    <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => props._submitForm(e, form.form_type)}><span className="fs-6">Submit</span></button>
                            :
                                <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../assets/images/Image-Loader.gif')} alt="Loader" /> <span className="fs-6">Submitting...</span></button>
                            }
                        
                            </p>
                        </Form>
                    ))}
                    </>
                }
            </Modal.Body>
            <Modal.Footer className="team-detail">

                <button className="btn btn-secondary" disabled={props.isProcess} onClick={(e) => props._toggleModal(e, false)}>Close</button>
            </Modal.Footer>
        </Modal>
      </>
    );
}
  
export default ModalMaster;