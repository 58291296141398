//New Entry Form Data
const fieldHub = [
      {colMd: "12", label: "Title", required: true, type: "textarea", name: "title", count: 250},
      {colMd: "12", label: "Logo", required: false, type: "image-upload", name: "logo"},
      {colMd: "5", label: "Opportunity Type", required: true, type: "multi-select", name: "opportunity_type_id", placeholderData: ["Select Opportunity Type"]},
      // {colMd: "6", label: "Opportunity Name", required: false, type: "text", name: "opportunity_name", count: 100, hide: true, tagline: "If Other- selected in 'Opportunity Type'"},
      {colMd: "7", label: "Other-Opportunity Type", required: false, type: "text", name: "opportunity_name", count: 100, hide: true, tagline: "for Multiple write comma seperated text"},
      {colMd: "5", label: "Eligibility By Course", required: true, type: "multi-select", name: "eligible_course_id", placeholderData: ["Select Course"]},
      {colMd: "7", label: "Other-Eligibility By Course", required: false, type: "text", name: "course_name", count: 100, hide: true, tagline: "for Multiple write comma seperated text"},
      {colMd: "5", label: "Eligibility Age", required: true, type: "multi-select", name: "eligible_age_id", placeholderData: ["Select Age"]},
      {colMd: "7", label: "Other-Eligibility Age", required: false, type: "text", name: "age_name", count: 100, hide: true, tagline: "for Multiple write comma seperated text"},
      {colMd: "5", label: "Benefits of Applying", required: false, type: "multi-select", name: "benefit_id", placeholderData: ["Select Benefits"]},
      {colMd: "7", label: "Other-Benefits of Applying", required: false, type: "text", name: "benefit_name", tagline: "for Multiple write comma seperated text", count: 100},
      {colMd: "12", label: "Key Benefit", required: false, type: "text", name: "key_benefit", count: 250},
      {colMd: "12", label: "Description", required: true, type: "ckeditor", name: "description", rows: 10, count: 2000},
      {colMd: "12", label: "Eligibility", required: false, type: "textarea", name: "eligibility", count: 250},
      {colMd: "12", label: "Duration", required: false, type: "text", name: "duration", count: 100},
      {colMd: "4", label: "Reserved for IFSPD Europe Members", required: false, type: "select", name: "is_reserved"},
      {colMd: "3", label: "Fee/Charges", required: true, type: "select", name: "fee_id"},
      {colMd: "2", label: "Currency Type", required: false, type: "select", name: "currency_type"},
      {colMd: "3", label: "Fees/Charges Amount", required: false, type: "number", name: "amount"},
      {colMd: "12", label: "Details of Reservation", required: false, type: "ckeditor", name: "reservation", count: 1000, hide: true, height: 200},
      {colMd: "12", label: "Apply Now URL", required: true, type: "textarea", name: "apply_link", count: 250},
      {colMd: "12", label: "Important Application Rules", required: false, type: "ckeditor", name: "imp_rule", count: 2000, height: 250},
      {colMd: "4", label: "Mode ", required: true, type: "multi-select", name: "mode_id", placeholderData: ["Select Mode"]},
      {colMd: "3", label: "Expiry Date", required: true, type: "date", name: "expiry_date", date_type: "future"},
      {colMd: "3", label: "Priority", required: false, type: "number", name: "priority"},
      ];
  const fieldValuesHub = {
    title: '',
    logo: '',
    logo_preview: '',
    opportunity_type_id: '',
    opportunity_name : '',
    key_benefit: '',
    description: '',
    benefit_id: '',
    benefit_name: '',
    mode_id: [{id: 1, label: 'Online'}],
    eligibility: '',
    eligible_age_id: '',
    age_name: '',
    eligible_course_id: '',
    course_name: '',
    duration: '',
    is_reserved: {id: 11, label: 'Not Reserved'},
    fee_id: {id: 6, label: 'Free'},
    currency_type: {id:  1, label: '₹'},
    amount: '',
    reservation: '',
    expiry_date: '',
    apply_link: '',
    imp_rule: '',
    show_image: true,
    priority: ''
  };
  // const fieldValuesHub = {
  //   title: 'Test 1',
  //   logo: '',
  //   opportunity_type_id: '',
  //   opportunity_name : '',
  //   description: 'Test Desc',
  //   benefit_id: '',
  //   mode_id: [{id: 1, label: 'Online'}],
  //   eligibility: 'Test Eli',
  //   eligible_age_id: '',
  //   eligible_course_id: '',
  //   duration: 'Test Dur',
  //   is_reserved: {id: 2, label: 'No'},
  //   reservation: 'Test Reser',
  //   expiry_date: '2024-11-30',
  //   apply_link: 'abc.com',
  //   show_image: true,
  //   priority: ''
  // };
  const fieldValidationHub = {
            title: 'required|max:250',
            logo: 'optional',
            opportunity_type_id: 'optional||Opportunity Type',
            opportunity_name : 'optional|max:100||Other-Opportunity Type',
            key_benefit: 'optional|max:250',
            description: 'required|max:2000',
            benefit_name: 'optional|max:100||Other-Benefits of Applying',
            benefit_id: 'optional||Benefits of Applying',
            mode_id: 'required',
            eligibility: 'optional|max:250',
            eligible_age_id: 'optional||Eligibility Age',
            age_name: 'optional|max:100||Other-Eligibility Age',
            eligible_course_id: 'optional||Eligibility By Course',
            course_name: 'optional|max:100||Other-Eligibility By Course',
            duration: 'optional|max:100',
            is_reserved: 'optional',
            fee_id: 'optional',
            currency_type: 'optional',
            amount: 'optional|digit:|min:1|max:5||Fees/Charges Amount',
            reservation: 'optional|max:1000||Details of Reservation',
            apply_link: 'required|max:250||Apply Now URL',
            imp_rule: 'optional|max:2000||Important Application Rules',
            expiry_date: 'required',
            priority: 'optional|decimalZero||Priority'
          };
  //View Form Data
  const fieldHubView = [
    {label: "Title", type: "string", value_key: "title"},
    {label: "Logo", type: "image", value_key: "logo"},
    {label: "Opportunity Type", type: "array", value_key: "type_details", array_key: "label"},
    {label: "Key Benefit", type: "string", value_key: "key_benefit"},
    {label: "Description", type: "html", value_key: "description"},
    {label: "Benefits of Applying", type: "array", value_key: "benefit_details", array_key: "label"},
    {label: "Mode ", type: "array", value_key: "mode_details", array_key: "label"},
    {label: "Eligibility", type: "string", value_key: "eligibility"},
    {label: "Eligibility by Age", type: "array", value_key: "age_details", array_key: "label"},
    {label: "Eligibility By Course", type: "array", value_key: "course_details", array_key: "label"},
    {label: "Duration", type: "string", value_key: "duration"},
    {label: "Reserved for IFSPD", type: "string", value_key: "reserved_value"},
    {label: "Details of Reservation", type: "html", value_key: "reservation_details"},
    {label: "Important Application Rules", type: "html", value_key: "imp_rule"},
    {label: "Fee/Charges", type: "string", value_key: "fee"},
    {label: "Fees/Charges Amount", type: "string", value_key: "amount"},
    {label: "Apply Now URL", type: "link_single", value_key: "apply_link"},
    {label: "Expiry Date", type: "date", value_key: "expiry_at", date_type: "Do MMM YYYY"},
    {label: "Priority", type: "string", value_key: "priority"},
    {label: "Status", type: "status", value_key: "status"},
    ];
  const data = {
    fieldHub,
    fieldValuesHub,
    fieldValidationHub,
    fieldHubView
  };
  
  export default data;