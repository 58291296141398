import React from 'react';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { LoaderMedium } from '../components/loader';

function ModalMasterDetail(props) {
    return (
      <> 
        <Modal dialogClassName="modal-70w" show={props.isShow||false} onHide={(e) => props._toggleModal(e, false)} size="lg" className="mod-custom team-detail view-details" backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                {props.loader 
                ?
                    <div className="text-center mt-5 mb-5">
                        <LoaderMedium size={45} />
                    </div>
                :
                    <>
                    {props._formData.map((form, form_index) => (
                        <div key={`form_${form_index}`}>
                            <div className="scroll-div">
                            <fieldset>
                                <legend>{form.label}</legend>
                                <div className="row m-0">
                                <div className="table-responsive ms-2">
                                    <table className="border-0 table-bordered table">
                                        <tbody>
                                            {form.fields.map((field, field_index) => (
                                                <tr key={`field_${field_index}`}>
                                                    <th className="w-25">{field.label}</th>
                                                    <td>
                                                    {field.type === "string"
                                                    ?
                                                        props.data[field.value_key]
                                                    :
                                                        field.type === "html"
                                                        ?
                                                            <div dangerouslySetInnerHTML={ { __html: props.data[field.value_key]}}></div>
                                                        :
                                                        field.type === "image"
                                                        ?
                                                            props.data[field.value_key] && (
                                                                <img src={props.data[field.value_key]} alt="Uploaded" />
                                                            )
                                                        :
                                                            field.type === "array"
                                                            ?
                                                                props.data[field.value_key] && props.data[field.value_key].length > 0 && (
                                                                    props.data[field.value_key].map((array_d, array_index) => (
                                                                        <span key={`${field.value_key}_${array_index}`}>
                                                                            {array_d[field.array_key]}
                                                                            {array_index !== props.data[field.value_key].length - 1 && (<>, &nbsp;</>)}
                                                                        </span>
                                                                    ))
                                                                )
                                                            :
                                                                field.type === "date"
                                                                ?
                                                                    props.data[field.value_key] && (
                                                                        moment(props.data[field.value_key]).format(field.date_type)
                                                                    )
                                                                :
                                                                    field.type === "status"
                                                                    ?
                                                                        <span className={`btn btn-sm ms-2 ${props.data.active_status_id === 1 ? 'btn-success' : 'btn-danger'}`}>{props.data[field.value_key]}</span>
                                                                    :
                                                                        field.type === "link_single"
                                                                        ?
                                                                            <a href={props.data[field.value_key]} target="_blank"  rel="noreferrer">{props.data[field.value_key]}</a>
                                                                        :
                                                                    
                                                                        <></>
                                                    }
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                </div>
                            </fieldset>
                            </div>
                        </div>
                    ))}
                    </>
                }
                </>
            </Modal.Body>
            <Modal.Footer className="team-detail">
                <button className="btn btn-secondary" onClick={(e) => props._toggleModal(e, false)}>Close</button>
            </Modal.Footer>
        </Modal>
      </>
    );
}
  
export default ModalMasterDetail;